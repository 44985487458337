import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Slide from "@mui/material/Slide";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import CircularProgress from "@mui/material/CircularProgress";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import MKBox from "components/MKBox";
import MKAlert from "components/MKAlert";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import { useTranslation } from "react-i18next";

function View({ children, title, height, ...rest }) {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState("");
  const [documentData, setDocumentData] = useState(null);
  const [sentences, setSentences] = useState([]);

  const countCharacters = (text) => {
    return text.length;
  };

  const handleTabType = (event, newValue) => setActiveTab(newValue);

  const handleReset = () => {
    setDocumentData("");
    setSentences("");
    setContent("");
  };

  const highlightText = (text, highlights) => {
    let highlightedText = [];
    let lastIndex = 0;

    highlights.forEach((highlight) => {
      const start = highlight.offset;
      const end = start + highlight.length;

      if (lastIndex < start) {
        highlightedText.push({
          text: text.slice(lastIndex, start),
          highlight: false,
        });
      }

      highlightedText.push({
        text: text.slice(start, end),
        highlight: true,
      });

      lastIndex = end;
    });

    if (lastIndex < text.length) {
      highlightedText.push({
        text: text.slice(lastIndex),
        highlight: false,
      });
    }

    return highlightedText;
  };

  const handleExecute = async () => {
    if (content.trim() !== "") {
      setLoading(true);
      try {
        const params = new URLSearchParams();
        params.append("content", content);
        params.append("action", "sentiment");
        const response = await axios.post(
          "https://dev.develop.coursemos.co.kr/api/dev_exp",
          params,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Accept: "application/json",
            },
          },
        );

        if (response.data.document && response.data.sentences) {
          setDocumentData(response.data.document);
          setSentences(response.data.sentences);
          setSuccess(true);
        } else if (response.data.error) {
          const errorCode = response.data.error.errorCode;
          switch (errorCode) {
            case "E003":
              setErrorMessage("문장의 길이가 초과 하였습니다.");
              break;
            default:
              setErrorMessage("변환에 실패하였습니다.");
              break;
          }
          setError(true);
        }
      } catch (error) {
        setErrorMessage("변환에 실패하였습니다.");
        setError(true);
      } finally {
        setLoading(false);
      }
    } else {
      setErrorMessage("내용을 입력하세요.");
      setError(true);
    }
  };
  useEffect(() => {
    if (success) {
      setTimeout(() => setSuccess(false), 3000);
    }
    if (error) {
      setTimeout(() => setError(false), 3000);
    }
  }, [success, error]);

  return (
    <MKBox
      width="100%"
      position="relative"
      borderRadius="xl"
      shadow="lg"
      mb={12}
      sx={{ overflow: "hidden" }}
      {...rest}
    >
      <MKBox
        px={3}
        sx={{
          borderBottom: ({ borders: { borderWidth, borderColor } }) =>
            `${borderWidth[1]} solid ${borderColor}`,
        }}
      >
        <Grid container spacing={2} justifyContent="space-between" py={1}>
          <Grid item xs={12} lg={3}>
            <MKTypography variant="body1" pt={0.5}>
              {title}
            </MKTypography>
          </Grid>
          <Grid item xs={12} lg={3}>
            <AppBar position="static">
              <Tabs value={activeTab} onChange={handleTabType}>
                <Tab
                  icon={
                    <MKBox
                      component="i"
                      color="dark"
                      mr={1.25}
                      sx={{ fontSize: ({ typography: { size } }) => size.sm }}
                      className="fas fa-desktop"
                    />
                  }
                  label={t("tabs.DETAILS")}
                />
                <Tab
                  icon={
                    <MKBox
                      component="i"
                      color="dark"
                      mr={1.25}
                      sx={{ fontSize: ({ typography: { size } }) => size.sm }}
                      className="fas fa-user-astronaut"
                    />
                  }
                  label={t("tabs.EXPERIENCE")}
                />
              </Tabs>
            </AppBar>
          </Grid>
        </Grid>
      </MKBox>
      <MKBox display={activeTab === 0 ? "block" : "none"}>
        <MKBox width="100%" p={3}>
          <MKBox
            bgColor="grey-100"
            width="100%"
            height={height}
            maxHeight="40rem"
            borderRadius="xl"
            sx={{ overflowX: "hidden", overflowY: "scroll" }}
          >
            {children}
          </MKBox>
        </MKBox>
      </MKBox>
      <MKBox display={activeTab === 1 ? "block" : "none"} p={3}>
        <MKBox
          bgColor="grey-100"
          position="relative"
          width="100%"
          borderRadius="xl"
          sx={{ overflow: "hidden" }}
        >
          <div style={{ position: "relative" }}>
            <textarea
              id="inputTextArea"
              rows={20}
              cols={50}
              placeholder="내용을 입력하세요."
              value={content}
              onChange={(e) => setContent(e.target.value)}
              style={{
                width: "calc(100% - 40px)",
                padding: "10px",
                margin: "20px",
                border: "2px solid #ccc",
                borderRadius: "5px",
                resize: "vertical",
                fontFamily: '"Noto Sans", sans-serif',
              }}
            />
            <MKTypography
              variant="subtitle2"
              style={{
                position: "absolute",
                bottom: "-1px",
                right: "30px",
                color: countCharacters(content) > 2000 ? "#f00" : "#0000FF",
              }}
            >
              글자 수: {countCharacters(content)} / 2000
            </MKTypography>
          </div>
          {documentData && (
            <MKBox
              border={10}
              borderColor="grey.300"
              p={2}
              mt={2}
              sx={{
                height,
                minHeight: "20rem",
                margin: 0,
              }}
            >
              <MKTypography variant="h6" color="success" gutterBottom>
                변환 결과
              </MKTypography>
              <IconButton
                aria-label="delete"
                onClick={handleReset}
                color="primary"
                sx={{
                  float: "right",
                  marginRight: "-10px",
                  marginTop: "-40px",
                }}
              >
                <DeleteIcon />
              </IconButton>
              <MKBox>
                <MKTypography variant="h4" gutterBottom>
                  Document Sentiment Analysis
                </MKTypography>
                <Card variant="outlined" style={{ marginBottom: "20px" }}>
                  <CardContent>
                    <MKTypography variant="h5" component="div" pt={1}>
                      감정 상태
                    </MKTypography>
                    <MKTypography
                      variant="body2"
                      pb={3}
                      style={{
                        fontWeight: "bold",
                        color:
                          documentData.sentiment === "neutral"
                            ? "orange"
                            : documentData.sentiment === "positive"
                              ? "green"
                              : documentData.sentiment === "negative"
                                ? "red"
                                : "inherit",
                      }}
                    >
                      {documentData.sentiment.toUpperCase()}
                    </MKTypography>

                    <MKTypography variant="h6" component="div">
                      정확도
                    </MKTypography>
                    <MKBox display="flex" alignItems="center">
                      <Icon color="warning" fontSize="30" mr={2}>
                        sentiment_neutral
                      </Icon>
                      <MKTypography variant="body2" style={{ marginLeft: 7 }}>
                        Neutral: {documentData.confidence.neutral} %
                      </MKTypography>
                    </MKBox>
                    <MKBox display="flex" alignItems="center">
                      <Icon color="success" fontSize="30" mr={2}>
                        mood
                      </Icon>
                      <MKTypography variant="body2" style={{ marginLeft: 7 }}>
                        Positive: {documentData.confidence.positive} %
                      </MKTypography>
                    </MKBox>
                    <MKBox display="flex" alignItems="center">
                      <Icon color="error" fontSize="30" mr={2}>
                        mood_bad
                      </Icon>
                      <MKTypography variant="body2" style={{ marginLeft: 7 }}>
                        Negative: {documentData.confidence.negative} %
                      </MKTypography>
                    </MKBox>
                  </CardContent>
                </Card>
                <MKTypography variant="h5" gutterBottom>
                  상세 감정 분석
                </MKTypography>
                <Grid container spacing={2}>
                  {sentences.map((sentence, index) => (
                    <Grid item xs={12} key={index}>
                      <Card variant="outlined">
                        <CardContent>
                          <MKTypography variant="h6" component="div" py={1}>
                            문장 {index + 1}
                          </MKTypography>
                          <MKTypography variant="body2">
                            내용: {sentence.content}
                          </MKTypography>
                          <MKBox display="flex" alignItems="center" pb={1}>
                            <MKTypography variant="body2">감정:</MKTypography>
                            {sentence.sentiment === "neutral" ? (
                              <Icon color="warning" fontSize="30">
                                sentiment_neutral
                              </Icon>
                            ) : sentence.sentiment === "positive" ? (
                              <Icon color="success" fontSize="30">
                                mood
                              </Icon>
                            ) : sentence.sentiment === "negative" ? (
                              <Icon color="error" fontSize="30">
                                mood_bad
                              </Icon>
                            ) : null}
                          </MKBox>
                          <MKTypography variant="h6" component="div">
                            정확도
                          </MKTypography>
                          <MKBox display="flex" alignItems="center">
                            <Icon color="warning" fontSize="30" mr={2}>
                              sentiment_neutral
                            </Icon>
                            <MKTypography
                              variant="body2"
                              style={{ marginLeft: 7 }}
                            >
                              Neutral: {sentence.confidence.neutral} %
                            </MKTypography>
                          </MKBox>
                          <MKBox display="flex" alignItems="center">
                            <Icon color="success" fontSize="30" mr={2}>
                              mood
                            </Icon>
                            <MKTypography
                              variant="body2"
                              style={{ marginLeft: 7 }}
                            >
                              Positive: {sentence.confidence.positive} %
                            </MKTypography>
                          </MKBox>
                          <MKBox display="flex" alignItems="center">
                            <Icon color="error" fontSize="30" mr={2}>
                              mood_bad
                            </Icon>
                            <MKTypography
                              variant="body2"
                              style={{ marginLeft: 7 }}
                            >
                              Negative: {sentence.confidence.negative} %
                            </MKTypography>
                          </MKBox>
                          <MKTypography variant="h6" component="div" pt={1}>
                            하이라이트
                          </MKTypography>
                          <MKTypography variant="body2">
                            {highlightText(
                              sentence.content,
                              sentence.highlights,
                            ).map((part, partIndex) => (
                              <span
                                key={partIndex}
                                style={{
                                  color: part.highlight ? "red" : "inherit",
                                }}
                              >
                                {part.text}
                              </span>
                            ))}
                          </MKTypography>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </MKBox>
            </MKBox>
          )}
          <MKBox textAlign="center" mt={2}>
            <MKButton
              variant="gradient"
              color="dark"
              onClick={handleExecute}
              fullWidth
            >
              실행
            </MKButton>
          </MKBox>
          {loading && (
            <MKBox textAlign="center" mt={2}>
              <CircularProgress />
            </MKBox>
          )}
          <Slide direction="down" in={error} unmountOnExit>
            <MKBox position="absolute" top="0.5rem" left={0} width="100%">
              <MKAlert
                width="100%"
                mx="auto"
                color="error"
                sx={{
                  minHeight: "3.5rem !important",
                  py: 1,
                  justifyContent: "center",
                }}
              >
                <MKTypography
                  variant="body2"
                  color="white"
                  fontWeight="regular"
                >
                  {errorMessage}
                </MKTypography>
              </MKAlert>
            </MKBox>
          </Slide>
          <Slide direction="down" in={success} unmountOnExit>
            <MKBox position="absolute" top="0.5rem" left={0} width="100%">
              <MKAlert
                width="100%"
                mx="auto"
                color="success"
                sx={{
                  minHeight: "3.5rem !important",
                  py: 1,
                  justifyContent: "center",
                }}
              >
                <MKTypography
                  variant="body2"
                  color="white"
                  fontWeight="regular"
                >
                  변환이 성공적으로 완료되었습니다.
                </MKTypography>
              </MKAlert>
            </MKBox>
          </Slide>
        </MKBox>
      </MKBox>
    </MKBox>
  );
}

View.defaultProps = {
  height: "auto",
};

View.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  height: PropTypes.string,
};

export default View;
