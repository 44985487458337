/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  All of the routes for the Material Kit 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Navbar.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `name` key is used for the name of the route on the Navbar.
  2. The `icon` key is used for the icon of the route on the Navbar.
  3. The `collapse` key is used for making a collapsible item on the Navbar that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  4. The `route` key is used to store the route location which is used for the react router.
  5. The `href` key is used to store the external links location.
  6. The `component` key is used to store the component of its route.
  7. The `dropdown` key is used to define that the item should open a dropdown for its collapse items .
  8. The `description` key is used to define the description of
          a route under its name.
  9. The `columns` key is used to define that how the content should look inside the dropdown menu as columns,
          you can set the columns amount based on this key.
  10. The `rowsPerColumn` key is used to define that how many rows should be in a column.
*/

// @mui material components
import Icon from "@mui/material/Icon";

// Intro
import TTSIntro from "./pages/Intro/TTS";
import SummaryIntro from "./pages/Intro/Summary";
import SentimentIntro from "./pages/Intro/Sentiment";
import OCRIntro from "./pages/Intro/OCR";
import STTIntro from "./pages/Intro/STT";
import SpeechIntro from "./pages/Intro/Speech";
import ChatbotIntro from "./pages/Intro/Chatbot";
import SMSIntro from "./pages/Intro/SMS";
import AnalysisIntro from "./pages/Intro/Analysis";
import SearchIntro from "./pages/Intro/Search";
import TensorFlowIntro from "./pages/Intro/TensorFlow";
import TranslateIntro from "./pages/Intro/Translate";

// Function
import TTSFunction from "./pages/Function/TTS";
import SummaryFunction from "./pages/Function/Summary";
import SentimentFunction from "./pages/Function/Sentiment";
import OCRFunction from "./pages/Function/OCR";
import STTFunction from "./pages/Function/STT";
import SpeechFunction from "./pages/Function/Speech";
import ChatbotFunction from "./pages/Function/Chatbot";
import SMSFunction from "./pages/Function/SMS";
import AnalysisFunction from "./pages/Function/Analysis";
import SearchFunction from "./pages/Function/Search";
import TensorFlowFunction from "./pages/Function/TensorFlow";
import TranslateFunction from "./pages/Function/Translate";

const routes = [
  {
    name: "AI",
    icon: <Icon>psychology</Icon>,
    columns: 2,
    rowsPerColumn: 4,
    collapse: [
      {
        name: "TTS",
        collapse: [
          {
            name: "Route.INTRO",
            route: "/ai/tts/intro",
            component: <TTSIntro />,
          },
          {
            name: "Route.FUNCTION",
            route: "/ai/tts/function",
            component: <TTSFunction />,
          },
        ],
      },
      {
        name: "Summary",
        collapse: [
          {
            name: "Route.INTRO",
            route: "/ai/summary/intro",
            component: <SummaryIntro />,
          },
          {
            name: "Route.FUNCTION",
            route: "/ai/summary/function",
            component: <SummaryFunction />,
          },
        ],
      },
      {
        name: "Sentiment",
        collapse: [
          {
            name: "Route.INTRO",
            route: "/ai/sentiment/intro",
            component: <SentimentIntro />,
          },
          {
            name: "Route.FUNCTION",
            route: "/ai/sentiment/function",
            component: <SentimentFunction />,
          },
        ],
      },
      {
        name: "OCR",
        collapse: [
          {
            name: "Route.INTRO",
            route: "/ai/ocr/intro",
            component: <OCRIntro />,
          },
          {
            name: "Route.FUNCTION",
            route: "/ai/ocr/function",
            component: <OCRFunction />,
          },
        ],
      },
      {
        name: "STT",
        collapse: [
          {
            name: "Route.INTRO",
            route: "/ai/stt/intro",
            component: <STTIntro />,
          },
          {
            name: "Route.FUNCTION",
            route: "/ai/stt/function",
            component: <STTFunction />,
          },
        ],
      },
      {
        name: "Speech",
        collapse: [
          {
            name: "Route.INTRO",
            route: "/ai/speech/intro",
            component: <SpeechIntro />,
          },
          {
            name: "Route.FUNCTION",
            route: "/ai/speech/function",
            component: <SpeechFunction />,
          },
        ],
      },
      {
        name: "Chatbot",
        collapse: [
          {
            name: "Route.INTRO",
            route: "/ai/chatbot/intro",
            component: <ChatbotIntro />,
          },
          {
            name: "Route.FUNCTION",
            route: "/ai/chatbot/function",
            component: <ChatbotFunction />,
          },
        ],
      },
      {
        name: "Translate",
        collapse: [
          {
            name: "Route.INTRO",
            route: "/ai/translate/intro",
            component: <TranslateIntro />,
          },
          {
            name: "Route.FUNCTION",
            route: "/ai/translate/function",
            component: <TranslateFunction />,
          },
        ],
      },
    ],
  },
  {
    name: "Notification",
    icon: <Icon>notifications</Icon>,
    columns: 2,
    rowsPerColumn: 4,
    collapse: [
      {
        name: "SMS",
        collapse: [
          {
            name: "Route.INTRO",
            route: "/notification/sms/intro",
            component: <SMSIntro />,
          },
          {
            name: "Route.FUNCTION",
            route: "/notification/sms/function",
            component: <SMSFunction />,
          },
        ],
      },
    ],
  },
  {
    name: "Bigdata",
    icon: <Icon>scatter_plot</Icon>,
    columns: 2,
    rowsPerColumn: 4,
    collapse: [
      {
        name: "Analysis",
        collapse: [
          {
            name: "Route.INTRO",
            route: "/bigdata/analysis/intro",
            component: <AnalysisIntro />,
          },
          {
            name: "Route.FUNCTION",
            route: "/bigdata/analysis/function",
            component: <AnalysisFunction />,
          },
        ],
      },
      {
        name: "Search",
        collapse: [
          {
            name: "Route.INTRO",
            route: "/bigdata/search/intro",
            component: <SearchIntro />,
          },
          {
            name: "Route.FUNCTION",
            route: "/bigdata/search/function",
            component: <SearchFunction />,
          },
        ],
      },
      {
        name: "TensorFlow",
        collapse: [
          {
            name: "Route.INTRO",
            route: "/bigdata/tensorflow/intro",
            component: <TensorFlowIntro />,
          },
          {
            name: "Route.FUNCTION",
            route: "/bigdata/tensorflow/function",
            component: <TensorFlowFunction />,
          },
        ],
      },
    ],
  },
  {
    name: "docs",
    icon: <Icon>article</Icon>,
    collapse: [
      {
        name: "Route.DOCS.TITLE1",
        description: "Route.DOCS.CONTENT1",
        href: "http://docs.coursemos.co.kr/docs/overview",
      },
      {
        name: "Route.DOCS.TITLE2",
        description: "Route.DOCS.CONTENT2",
        href: "http://docs.coursemos.co.kr/docs/tts",
      },
      {
        name: "Route.DOCS.TITLE3",
        description: "Route.DOCS.CONTENT3",
        href: "http://docs.coursemos.co.kr/docs/sms",
      },
      {
        name: "Route.DOCS.TITLE4",
        description: "Route.DOCS.CONTENT4",
        href: "http://docs.coursemos.co.kr/docs/analysis",
      },
    ],
  },
];

export default routes;
