/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import FilledInfoCard from "coursemos/Cards/InfoCards/FilledInfoCard";
import SpeechImg1 from "assets/images/function/speech1.jpg";
import SpeechImg2 from "assets/images/function/speech2.jpg";
import SpeechTable from "pages/Function/Speech/table";

// Translation
import { useTranslation } from "react-i18next";

const HorizontalLine = (
  <hr
    style={{
      border: "none",
      borderBottom: "1px solid #eee",
      margin: "0 0 16px 0",
    }}
  />
);

function FeaturesOne() {
  const { t } = useTranslation();
  return (
    <MKBox component="section" py={{ xs: 3, md: 12 }}>
      <Container>
        <Grid container alignItems="center">
          <Grid item xs={12} lg={11}>
            <MKBox display="flex" alignItems="center">
              <MKBox
                width="3rem"
                height="3rem"
                variant="gradient"
                bgColor="info"
                color="white"
                coloredShadow="info"
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderRadius="xl"
              >
                <Icon fontSize="small">star</Icon>
              </MKBox>
              <MKTypography variant="h3" pl={2} my={1}>
                {t(`function.speech.content.ITEMS1.TITLE`)}
              </MKTypography>
            </MKBox>
            {HorizontalLine}
            <MKTypography variant="h5" color="text" mb={2}>
              {t(`function.speech.content.ITEMS1.SUBTITLE1`)}
            </MKTypography>
            <MKTypography variant="body2" color="text" mb={4}>
              {t(`function.speech.content.ITEMS1.CONTENT1`)}
            </MKTypography>
            <MKTypography variant="h5" color="text" mb={2}>
              {t(`function.speech.content.ITEMS1.SUBTITLE2`)}
            </MKTypography>
            <MKTypography variant="body2" color="text" mb={10}>
              {t(`function.speech.content.ITEMS1.CONTENT2`)}
            </MKTypography>
            <MKBox display="flex" alignItems="center">
              <MKBox
                width="3rem"
                height="3rem"
                variant="gradient"
                bgColor="info"
                color="white"
                coloredShadow="info"
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderRadius="xl"
              >
                <Icon fontSize="small">wb_incandescent</Icon>
              </MKBox>
              <MKTypography variant="h3" pl={2} my={1}>
                {t(`function.speech.content.ITEMS2.TITLE`)}
              </MKTypography>
            </MKBox>
            {HorizontalLine}
            <MKTypography variant="h5" color="text" mb={5}>
              {t(`function.speech.content.ITEMS2.SUBTITLE1`)}
            </MKTypography>
            <MKBox display="flex" alignItems="center">
              <MKBox style={{ width: "50%" }} display="flex" mb={4}>
                <MKBox
                  width="3rem"
                  height="3rem"
                  variant="gradient"
                  bgColor="error"
                  color="light"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                  margin="1rem 2rem 1rem 1rem"
                >
                  <Icon fontSize="large">closed_caption_outlined</Icon>
                </MKBox>
                <MKBox style={{ width: "70%" }}>
                  <MKTypography variant="h6" color="text" mb={2}>
                    {t(`function.speech.content.ITEMS2.SUBTITLE2`)}
                  </MKTypography>
                  <MKTypography variant="body2" color="text" mb={4}>
                    {t(`function.speech.content.ITEMS2.CONTENT1`)}
                    <br />
                    {t(`function.speech.content.ITEMS2.CONTENT2`)}
                  </MKTypography>
                </MKBox>
              </MKBox>
              <MKBox style={{ width: "50%" }} display="flex" mb={4}>
                <MKBox
                  width="3rem"
                  height="3rem"
                  variant="gradient"
                  bgColor="error"
                  color="light"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                  margin="1rem 2rem 1rem 1rem"
                >
                  <Icon fontSize="large">support_agent_outlined</Icon>
                </MKBox>
                <MKBox style={{ width: "70%" }}>
                  <MKTypography variant="h6" color="text" mb={2}>
                    {t(`function.speech.content.ITEMS2.SUBTITLE3`)}
                  </MKTypography>
                  <MKTypography variant="body2" color="text" mb={4}>
                    {t(`function.speech.content.ITEMS2.CONTENT3`)}
                    <br />
                    {t(`function.speech.content.ITEMS2.CONTENT4`)}
                  </MKTypography>
                </MKBox>
              </MKBox>
            </MKBox>
            <MKBox display="flex" alignItems="center">
              <MKBox style={{ width: "50%" }} display="flex" mb={4}>
                <MKBox
                  width="3rem"
                  height="3rem"
                  variant="gradient"
                  bgColor="error"
                  color="light"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                  margin="1rem 2rem 1rem 1rem"
                >
                  <Icon fontSize="large">videocam_outlined</Icon>
                </MKBox>
                <MKBox style={{ width: "70%" }}>
                  <MKTypography variant="h6" color="text" mb={2}>
                    {t(`function.speech.content.ITEMS2.SUBTITLE4`)}
                  </MKTypography>
                  <MKTypography variant="body2" color="text" mb={4}>
                    {t(`function.speech.content.ITEMS2.CONTENT5`)}
                    <br />
                    {t(`function.speech.content.ITEMS2.CONTENT6`)}
                  </MKTypography>
                </MKBox>
              </MKBox>
              <MKBox style={{ width: "50%" }} display="flex" mb={4}>
                <MKBox
                  width="3rem"
                  height="3rem"
                  variant="gradient"
                  bgColor="error"
                  color="light"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                  margin="1rem 2rem 1rem 1rem"
                >
                  <Icon fontSize="large">subtitles_outlined</Icon>
                </MKBox>
                <MKBox style={{ width: "70%" }}>
                  <MKTypography variant="h6" color="text" mb={2}>
                    {t(`function.speech.content.ITEMS2.SUBTITLE5`)}
                  </MKTypography>
                  <MKTypography variant="body2" color="text" mb={4}>
                    {t(`function.speech.content.ITEMS2.CONTENT7`)}
                    <br />
                    {t(`function.speech.content.ITEMS2.CONTENT8`)}
                  </MKTypography>
                </MKBox>
              </MKBox>
            </MKBox>
            <MKBox display="flex" alignItems="center">
              <MKBox
                width="3rem"
                height="3rem"
                variant="gradient"
                bgColor="info"
                color="white"
                coloredShadow="info"
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderRadius="xl"
              >
                <Icon fontSize="small">manage_accounts</Icon>
              </MKBox>
              <MKBox pl={2} my={1}>
                <MKTypography variant="h3">
                  {t(`function.speech.content.ITEMS3.TITLE`)}
                </MKTypography>
              </MKBox>
            </MKBox>
            {HorizontalLine}
            <MKTypography variant="h5" color="text" mb={2}>
              {t(`function.speech.content.ITEMS3.SUBTITLE1`)}
            </MKTypography>
            <MKTypography variant="body2" color="text" mb={4}>
              {t(`function.speech.content.ITEMS3.CONTENT1`)}
              <br />
              <br />
              {t(`function.speech.content.ITEMS3.CONTENT2`)}
            </MKTypography>
            <MKTypography variant="h5" color="text" mb={2}>
              {t(`function.speech.content.ITEMS3.SUBTITLE2`)}
            </MKTypography>
            <MKTypography variant="body2" color="text" mb={4}>
              {t(`function.speech.content.ITEMS3.CONTENT3`)}
            </MKTypography>
            <MKTypography variant="h5" color="text" mb={2}>
              {t(`function.speech.content.ITEMS3.SUBTITLE3`)}
            </MKTypography>
            <MKTypography variant="body2" color="text" mb={4}>
              {t(`function.speech.content.ITEMS3.CONTENT4`)}
              <br />
              {t(`function.speech.content.ITEMS3.CONTENT5`)}
              <br />
              {t(`function.speech.content.ITEMS3.CONTENT6`)}
            </MKTypography>
            <MKBox mb={6}>
              <img
                src={SpeechImg1}
                alt="Speech"
                style={{ width: "100%", height: "auto" }}
              />
            </MKBox>
            <MKTypography variant="h5" color="text" mb={2}>
              {t(`function.speech.content.ITEMS3.SUBTITLE4`)}
            </MKTypography>
            <MKTypography variant="body2" color="text" mb={4}>
              {t(`function.speech.content.ITEMS3.CONTENT7`)}
              <br />
              <br />
              {t(`function.speech.content.ITEMS3.CONTENT8`)}
            </MKTypography>
            <MKTypography variant="h5" color="text" mt={6} mb={2}>
              {t(`function.speech.content.ITEMS3.SUBTITLE5`)}
            </MKTypography>
            <MKTypography variant="body2" color="text" mb={4}>
              {t(`function.speech.content.ITEMS3.CONTENT9`)}
            </MKTypography>
            <SpeechTable />
            <MKBox my={6}>
              <img
                src={SpeechImg2}
                alt="Speech"
                style={{ width: "100%", height: "auto" }}
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
      {HorizontalLine}
      <Container>
        <Grid container>
          <Grid item xs={12} lg={5}>
            <FilledInfoCard
              color="info"
              icon="article"
              title={t(`function.speech.content.ITEMS4.TITLE1`)}
              description={<>{t(`function.speech.content.ITEMS4.CONTENT1`)}</>}
              action={{
                type: "external",
                route: "https://docs.coursemos.co.kr/docs/speech",
                label: "Read docs",
              }}
            />
          </Grid>
          <Grid item xs={12} lg={5}>
            <FilledInfoCard
              color="info"
              icon="help_outline"
              title={t(`function.speech.content.ITEMS4.TITLE2`)}
              description={<>{t(`function.speech.content.ITEMS4.CONTENT2`)}</>}
              action={{
                type: "external",
                route: "/support",
                label: "Let's go",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default FeaturesOne;
