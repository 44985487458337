import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Slide from "@mui/material/Slide";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import CircularProgress from "@mui/material/CircularProgress";
import MKBox from "components/MKBox";
import MKAlert from "components/MKAlert";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import { useTranslation } from "react-i18next";

function View({ children, title, height, ...rest }) {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [apiResponse, setApiResponse] = useState("");
  const [fileContent, setFileContent] = useState("");
  const [fileName, setFileName] = useState("");

  const handleTabType = (event, newValue) => setActiveTab(newValue);

  const handleReset = () => {
    setApiResponse("");
    setFileContent("");
    setFileName("");
  };
  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    setFileName(file.name);

    const reader = new FileReader();
    reader.onload = () => {
      setFileContent(file);
    };
    reader.readAsText(file);
  }, []);

  const handleExecute = async () => {
    if (fileContent) {
      setLoading(true);
      try {
        const formData = new FormData();
        formData.append("file", fileContent);
        formData.append("action", "speech");
        const response = await axios.post(process.env.REACT_APP_API, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        });
        setApiResponse(response.data);
        setSuccess(true);
      } catch (error) {
        console.error("Error uploading file:", error);
        setError(true);
      } finally {
        setLoading(false);
      }
    } else {
      setError(true);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "audio/mpeg": [".mp3"],
      "audio/aac": [".aac"],
      "audio/ac3": [".ac3"],
      "audio/ogg": [".ogg"],
      "audio/flac": [".flac"],
      "audio/wav": [".wav"],
    },
  });

  useEffect(() => {
    if (success) {
      setTimeout(() => setSuccess(false), 3000);
    }
    if (error) {
      setTimeout(() => setError(false), 3000);
    }
  }, [success, error]);

  return (
    <MKBox
      width="100%"
      position="relative"
      borderRadius="xl"
      shadow="lg"
      mb={12}
      sx={{ overflow: "hidden" }}
      {...rest}
    >
      <MKBox
        px={3}
        sx={{
          borderBottom: ({ borders: { borderWidth, borderColor } }) =>
            `${borderWidth[1]} solid ${borderColor}`,
        }}
      >
        <Grid container spacing={2} justifyContent="space-between" py={1}>
          <Grid item xs={12} lg={3}>
            <MKTypography variant="body1" pt={0.5}>
              {title}
            </MKTypography>
          </Grid>
          <Grid item xs={12} lg={3}>
            <AppBar position="static">
              <Tabs value={activeTab} onChange={handleTabType}>
                <Tab
                  icon={
                    <MKBox
                      component="i"
                      color="dark"
                      mr={1.25}
                      sx={{ fontSize: ({ typography: { size } }) => size.sm }}
                      className="fas fa-desktop"
                    />
                  }
                  label={t("tabs.DETAILS")}
                />
                <Tab
                  icon={
                    <MKBox
                      component="i"
                      color="dark"
                      mr={1.25}
                      sx={{ fontSize: ({ typography: { size } }) => size.sm }}
                      className="fas fa-user-astronaut"
                    />
                  }
                  label={t("tabs.EXPERIENCE")}
                />
              </Tabs>
            </AppBar>
          </Grid>
        </Grid>
      </MKBox>
      <MKBox display={activeTab === 0 ? "block" : "none"}>
        <MKBox width="100%" p={3}>
          <MKBox
            bgColor="grey-100"
            width="100%"
            height={height}
            maxHeight="40rem"
            borderRadius="xl"
            sx={{ overflowX: "hidden", overflowY: "scroll" }}
          >
            {children}
          </MKBox>
        </MKBox>
      </MKBox>
      <MKBox display={activeTab === 1 ? "block" : "none"} p={3}>
        <MKBox
          bgColor="grey-100"
          position="relative"
          width="100%"
          borderRadius="xl"
          sx={{ overflow: "hidden" }}
        >
          <div
            /* eslint-disable */
            {...getRootProps({ className: "dropzone" })}
            style={{
              border: "2px dashed #ccc",
              padding: "20px",
              textAlign: "center",
            }}
          >
            <input {...getInputProps()} />
            파일을 여기에 드롭하거나 클릭하여 업로드하세요.
          </div>
          {apiResponse && (
            <MKBox
              border={10}
              borderColor="grey.300"
              p={2}
              mt={2}
              sx={{
                height,
                minHeight: "20rem",
                maxHeight: "40rem",
                overflowY: "auto",
                margin: 0,
              }}
            >
              <MKTypography variant="h6" color="success" gutterBottom>
                변환 결과
              </MKTypography>
              {apiResponse && (
                <IconButton
                  aria-label="delete"
                  onClick={handleReset}
                  color="primary"
                  sx={{ float: "right", marginRight: "-10px", marginTop: "-40px" }}
                >
                  <DeleteIcon />
                </IconButton>
              )}
              <MKTypography
                variant="subtitle2"
                color="primary"
                component="pre"
                sx={{
                  whiteSpace: 'pre-wrap',
                  wordWrap: 'break-word',
                  backgroundColor: 'transparent',
                  color: '#000',
                  fontSize: '1rem',
                  padding: '1rem',
                  boxSizing: 'border-box',
                }}
              >
                {apiResponse}
              </MKTypography>
            </MKBox>
          )}
          {fileName && (
            <MKTypography
              variant="body2"
              color="secondary"
              textAlign="center"
              mt={2}
            >
              업로드된 파일: {fileName}
            </MKTypography>
          )}
          <MKBox textAlign="center" mt={2}>
            <MKButton
              variant="gradient"
              color="dark"
              onClick={handleExecute}
              fullWidth
            >
              실행
            </MKButton>
          </MKBox>
          {loading && (
            <MKBox textAlign="center" mt={2}>
              <CircularProgress />
            </MKBox>
          )}
          <Slide direction="down" in={error} unmountOnExit>
            <MKBox position="absolute" top="0.5rem" left={0} width="100%">
              <MKAlert
                width="100%"
                mx="auto"
                color="error"
                sx={{
                  minHeight: "3.5rem !important",
                  py: 1,
                  justifyContent: "center",
                }}
              >
                <MKTypography
                  variant="body2"
                  color="white"
                  fontWeight="regular"
                >
                  변환에 실패하였습니다.
                </MKTypography>
              </MKAlert>
            </MKBox>
          </Slide>
          <Slide direction="down" in={success} unmountOnExit>
            <MKBox position="absolute" top="0.5rem" left={0} width="100%">
              <MKAlert
                width="100%"
                mx="auto"
                color="success"
                sx={{
                  minHeight: "3.5rem !important",
                  py: 1,
                  justifyContent: "center",
                }}
              >
                <MKTypography
                  variant="body2"
                  color="white"
                  fontWeight="regular"
                >
                  변환이 성공적으로 완료되었습니다.
                </MKTypography>
              </MKAlert>
            </MKBox>
          </Slide>
        </MKBox>
      </MKBox>
    </MKBox>
  );
}

View.defaultProps = {
  height: "auto",
};

View.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  height: PropTypes.string,
};

export default View;
