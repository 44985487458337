/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Sections components
import BaseLayout from "pages/BaseLayout";
import View from "pages/View/Summary";

// Features page components
import SummaryContent from "pages/Function/Summary/content";

// Translation
import { useTranslation } from "react-i18next";

function Features() {
  const { t } = useTranslation();
  return (
    <BaseLayout
      title={t(`function.summary.TITLE`)}
      breadcrumb={[
        { label: "Page Sections", route: "/ai/summary/function" },
        { label: t(`function.summary.TITLE`) },
      ]}
    >
      <View title="">
        <SummaryContent />
      </View>
    </BaseLayout>
  );
}

export default Features;
