/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import PropTypes from "prop-types";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

// Translation
import { useTranslation } from "react-i18next";

const tableData = [
  {
    label: "function.speech.table.BODY.ITEM1.CONTENT1",
    content1: "function.speech.table.BODY.ITEM1.CONTENT2",
    content2: {
      line1: "function.speech.table.BODY.ITEM1.CONTENT3",
      line2: "function.speech.table.BODY.ITEM1.CONTENT4",
      line3: "function.speech.table.BODY.ITEM1.CONTENT5",
      line4: "function.speech.table.BODY.ITEM1.CONTENT6",
      line5: "function.speech.table.BODY.ITEM1.CONTENT7",
    },
    content3: "function.speech.table.BODY.ITEM1.CONTENT8",
    content4: "function.speech.table.BODY.ITEM1.CONTENT9",
    content5: {
      line1: "function.speech.table.BODY.ITEM1.CONTENT10",
      line2: "function.speech.table.BODY.ITEM1.CONTENT11",
    },
  },
  {
    label: "function.speech.table.BODY.ITEM2.CONTENT1",
    content1: "function.speech.table.BODY.ITEM2.CONTENT2",
    content2: {
      line1: "function.speech.table.BODY.ITEM2.CONTENT3",
      line2: "function.speech.table.BODY.ITEM2.CONTENT4",
      line3: "function.speech.table.BODY.ITEM2.CONTENT5",
    },
    content3: "function.speech.table.BODY.ITEM2.CONTENT6",
    content4: "-",
    content5: {
      line1: "function.speech.table.BODY.ITEM2.CONTENT7",
    },
  },
  {
    label: "function.speech.table.BODY.ITEM3.CONTENT1",
    content1: "function.speech.table.BODY.ITEM3.CONTENT2",
    content2: {
      line1: "function.speech.table.BODY.ITEM3.CONTENT3",
      line2: "function.speech.table.BODY.ITEM3.CONTENT4",
      line3: "function.speech.table.BODY.ITEM3.CONTENT5",
      line4: "function.speech.table.BODY.ITEM3.CONTENT6",
    },
    content3: "function.speech.table.BODY.ITEM3.CONTENT7",
    content4: "function.speech.table.BODY.ITEM3.CONTENT8",
    content5: {
      line1: "function.speech.table.BODY.ITEM3.CONTENT9",
    },
  },
];

const TableHeaderCell = ({ children }) => (
  <TableCell
    align="center"
    style={{
      fontWeight: "bold",
      minWidth: "140px",
      borderRight: "1px solid #eee",
    }}
  >
    {children}
  </TableCell>
);

TableHeaderCell.propTypes = {
  children: PropTypes.node.isRequired,
};

const TableCellContent = ({ children }) => (
  <TableCell align="center" style={{ borderRight: "1px solid #eee" }}>
    {children}
  </TableCell>
);

TableCellContent.propTypes = {
  children: PropTypes.node.isRequired,
};

function Tables() {
  const { t } = useTranslation();
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          <TableRow>
            <TableHeaderCell>
              {t(`function.speech.table.HEAD.TITLE1`)}
            </TableHeaderCell>
            <TableHeaderCell>
              {t(`function.speech.table.HEAD.TITLE2`)}
            </TableHeaderCell>
            <TableHeaderCell>
              {t(`function.speech.table.HEAD.TITLE3`)}
            </TableHeaderCell>
            <TableHeaderCell>
              {t(`function.speech.table.HEAD.TITLE4`)}
            </TableHeaderCell>
            <TableHeaderCell>
              {t(`function.speech.table.HEAD.TITLE5`)}
            </TableHeaderCell>
            <TableHeaderCell>
              {t(`function.speech.table.HEAD.TITLE6`)}
            </TableHeaderCell>
          </TableRow>
          {tableData.map((row, index) => (
            <TableRow key={index}>
              <TableCellContent>{t(row.label)}</TableCellContent>
              <TableCellContent>{t(row.content1)}</TableCellContent>
              <TableCellContent>
                {row.content2.line1 && t(row.content2.line1)}
                {row.content2.line1 && <br />}
                {row.content2.line2 && t(row.content2.line2)}
                {row.content2.line2 && <br />}
                {row.content2.line3 && t(row.content2.line3)}
                {row.content2.line3 && <br />}
                {row.content2.line4 && t(row.content2.line4)}
                {row.content2.line4 && <br />}
                {row.content2.line5 && t(row.content2.line5)}
              </TableCellContent>
              <TableCellContent>{t(row.content3)}</TableCellContent>
              <TableCellContent>{t(row.content4)}</TableCellContent>
              <TableCellContent>
                {row.content5.line1 && t(row.content5.line1)}
                {row.content5.line1 && <br />}
                {row.content5.line2 && t(row.content5.line2)}
              </TableCellContent>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default Tables;
