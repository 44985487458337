import * as React from "react";
import PropTypes from "prop-types";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useTranslation } from "react-i18next";

LanguageSelect.propTypes = {
  textColor: PropTypes.string,
};

export default function LanguageSelect({ textColor }) {
  const { i18n } = useTranslation();

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  const handleChange = (event) => {
    changeLanguage(event.target.value);
  };

  const languages = ["ko", "en"];
  const languageLabels = {
    ko: "한국어",
    en: "English",
  };

  return (
    <FormControl sx={{ width: "120px" }} style={{ marginLeft: "40px" }}>
      <Select
        labelId="language-select-label"
        id="language-select"
        value={i18n.language}
        onChange={handleChange}
        sx={{
          borderRadius: 20,
          height: "30px",
          border: "1px solid black",
          "& .MuiSelect-select": {
            paddingTop: "8px",
            paddingBottom: "8px",
            minHeight: "auto",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "black",
          },
        }}
        IconComponent={() => (
          <ArrowDropDownIcon
            sx={{ color: "black" }}
            style={{ marginRight: "10px" }}
          />
        )}
      >
        {languages.map((lang) => (
          <MenuItem key={lang} value={lang}>
            <span style={{ fontSize: "0.8rem", color: textColor }}>
              {languageLabels[lang]}
            </span>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
