// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LaunchIcon from "@mui/icons-material/Launch";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/white-logo.svg";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "address.NAME",
    subname: "address.SUBNAME",
    image: logoCT,
    route: "/",
  },
  socials: [
    {
      icon: <FacebookIcon />,
      link: "https://facebook.com/sharer/sharer.php?u=https://service.coursemos.co.kr/",
    },
    {
      icon: <TwitterIcon />,
      link: "https://twitter.com/intent/tweet?url=https://service.coursemos.co.kr/&amp;text=Coursemos",
    },
    {
      icon: <LinkedInIcon />,
      link: "https://www.linkedin.com/shareArticle?mini=true&amp;url=https:service.coursemos.co.kr",
    },
  ],
  menus: [
    {
      name: "CONTACT",
      items: [
        {
          name: "Home",
          href: "/",
        },
        {
          name: "Service",
          icon: <LaunchIcon />,
          href: "https://service.coursemos.co.kr/",
        },
        {
          name: "FAQ",
          icon: <LaunchIcon />,
          href: "https://service.coursemos.co.kr/auth/login?menu=profile",
        },
        {
          name: "About",
          icon: <LaunchIcon />,
          href: "https://service.coursemos.co.kr/auth/login?menu=profile",
        },
      ],
    },
    {
      name: "AI",
      items: [
        {
          name: "TTS",
          href: "https://dev.coursemos.co.kr/ai/tts/intro",
        },
        {
          name: "STT",
          href: "https://dev.coursemos.co.kr/ai/stt/intro",
        },
        {
          name: "Summary",
          href: "https://dev.coursemos.co.kr/ai/summary/intro",
        },
        {
          name: "Speech",
          href: "https://dev.coursemos.co.kr/ai/speech/intro",
        },
        {
          name: "Sentiment",
          href: "https://dev.coursemos.co.kr/ai/sentiment/intro",
        },
        {
          name: "Chatbot",
          href: "https://dev.coursemos.co.kr/ai/chatbot/intro",
        },
        {
          name: "OCR",
          href: "https://dev.coursemos.co.kr/ai/ocr/intro",
        },
        {
          name: "Translate",
          href: "https://dev.coursemos.co.kr/ai/translate/intro",
        },
      ],
    },
    {
      name: "Notification",
      items: [
        {
          name: "SMS / MMS / LMS",
          href: "https://dev.coursemos.co.kr/notification/sms/intro",
        },
      ],
    },
    {
      name: "BigData",
      items: [
        {
          name: "Analysis",
          href: "https://dev.coursemos.co.kr/bigdata/analysis/intro",
        },
        {
          name: "Search Engine",
          href: "https://dev.coursemos.co.kr/bigdata/search/intro",
        },
        {
          name: "TensorFlow",
          href: "https://dev.coursemos.co.kr/bigdata/tensorflow/intro",
        },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      &copy; {date} Ubion, {"Coursemos"}, Service Develop Team
      <MKTypography
        component="a"
        href="https://dev.coursemos.co.kr/"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        . All rights reserved.
      </MKTypography>
    </MKTypography>
  ),
};
