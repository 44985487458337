/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Sections components
import BaseLayout from "pages/BaseLayout";
import View from "pages/View";

// Features page components
import ChatbotContent from "pages/Function/Chatbot/content";

// Features page components code
import ChatbotCode from "pages/Function/Chatbot/content/code";

// Translation
import { useTranslation } from "react-i18next";

function Features() {
  const { t } = useTranslation();
  return (
    <BaseLayout
      title={t(`function.chatbot.TITLE`)}
      breadcrumb={[
        { label: "Page Sections", route: "/ai/chatbot/function" },
        { label: t(`function.chatbot.TITLE`) },
      ]}
    >
      <View title="" code={ChatbotCode}>
        <ChatbotContent />
      </View>
    </BaseLayout>
  );
}

export default Features;
