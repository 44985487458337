import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Slide from "@mui/material/Slide";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MKBox from "components/MKBox";
import MKAlert from "components/MKAlert";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import Switch from "@mui/material/Switch";
import { useTranslation } from "react-i18next";

function View({ children, title, height, ...rest }) {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [apiResponse, setApiResponse] = useState("");
  const [text, setText] = useState("");

  const [source, setSource] = useState("auto");
  const [target, setTarget] = useState("en");
  const [honorific, setHonorific] = useState(false);

  const handleTabType = (event, newValue) => setActiveTab(newValue);

  const handleReset = () => {
    setApiResponse("");
    setText("");
  };

  const handleExecute = async () => {
    if (text.trim() !== "") {
      setLoading(true);
      try {
        const params = new URLSearchParams();
        params.append("source", source);
        params.append("target", target);
        params.append("text", text);
        params.append("honorific", honorific);
        params.append("action", "translate");
        const response = await axios.post(process.env.REACT_APP_API, params, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "application/json",
          },
        });
        if (response.data.message) {
          setApiResponse(response.data.message.result);
          setSuccess(true);
        } else if (response.data.error) {
          const errorCode = response.data.error.errorCode;
          switch (errorCode) {
            case "N2MT01":
              setErrorMessage("정확한 값을 입력해주시기 바랍니다.");
              break;
            case "N2MT05":
              setErrorMessage("원본 언어와 목적 언어의 값이 동일합니다.");
              break;
            case "N2MT07":
              setErrorMessage("텍스트를 입력해주세요.");
              break;
            case "N2MT08":
              setErrorMessage("텍스트의 최대 길이를 초과했습니다.");
              break;
            default:
              setErrorMessage("변환에 실패하였습니다.");
              break;
          }
          setError(true);
        }
      } catch (error) {
        setErrorMessage("변환에 실패하였습니다.");
        setError(true);
      } finally {
        setLoading(false);
      }
    } else {
      setErrorMessage("내용을 입력하세요.");
      setError(true);
    }
  };

  const languages = [
    "auto",
    "ko",
    "en",
    "ja",
    "zh-CN",
    "zh-TW",
    "vi",
    "th",
    "id",
    "fr",
    "es",
    "ru",
    "de",
    "it",
  ];

  const targetLanguages = [
    "ko",
    "en",
    "ja",
    "zh-CN",
    "zh-TW",
    "vi",
    "th",
    "id",
    "fr",
    "es",
    "ru",
    "de",
    "it",
  ];

  const languageLabels = {
    auto: "자동",
    ko: "한국어",
    en: "영어",
    ja: "일본어",
    "zh-CN": "중국어(간체)",
    "zh-TW": "중국어(번체)",
    vi: "베트남어",
    th: "태국어",
    id: "인도네시아어",
    fr: "프랑스어",
    es: "스페인어",
    ru: "러시아어",
    de: "독일어",
    it: "이탈리아어",
  };

  useEffect(() => {
    if (success) {
      setTimeout(() => setSuccess(false), 3000);
    }
    if (error) {
      setTimeout(() => setError(false), 3000);
    }
  }, [success, error]);

  return (
    <MKBox
      width="100%"
      position="relative"
      borderRadius="xl"
      shadow="lg"
      mb={12}
      sx={{ overflow: "hidden" }}
      {...rest}
    >
      <MKBox
        px={3}
        sx={{
          borderBottom: ({ borders: { borderWidth, borderColor } }) =>
            `${borderWidth[1]} solid ${borderColor}`,
        }}
      >
        <Grid container spacing={2} justifyContent="space-between" py={1}>
          <Grid item xs={12} lg={3}>
            <MKTypography variant="body1" pt={0.5}>
              {title}
            </MKTypography>
          </Grid>
          <Grid item xs={12} lg={3}>
            <AppBar position="static">
              <Tabs value={activeTab} onChange={handleTabType}>
                <Tab
                  icon={
                    <MKBox
                      component="i"
                      color="dark"
                      mr={1.25}
                      sx={{ fontSize: ({ typography: { size } }) => size.sm }}
                      className="fas fa-desktop"
                    />
                  }
                  label={t("tabs.DETAILS")}
                />
                <Tab
                  icon={
                    <MKBox
                      component="i"
                      color="dark"
                      mr={1.25}
                      sx={{ fontSize: ({ typography: { size } }) => size.sm }}
                      className="fas fa-user-astronaut"
                    />
                  }
                  label={t("tabs.EXPERIENCE")}
                />
              </Tabs>
            </AppBar>
          </Grid>
        </Grid>
      </MKBox>
      <MKBox display={activeTab === 0 ? "block" : "none"}>
        <MKBox width="100%" p={3}>
          <MKBox
            bgColor="grey-100"
            width="100%"
            height={height}
            maxHeight="40rem"
            borderRadius="xl"
            sx={{ overflowX: "hidden", overflowY: "scroll" }}
          >
            {children}
          </MKBox>
        </MKBox>
      </MKBox>
      <MKBox display={activeTab === 1 ? "block" : "none"} p={3}>
        <MKBox
          bgColor="grey-100"
          position="relative"
          width="100%"
          borderRadius="xl"
          sx={{ overflow: "hidden" }}
        >
          <MKBox
            pt={2}
            pr={2}
            pl={2}
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
          >
            <MKBox display="flex" alignItems="center">
              <MKTypography variant="h6" gutterBottom mr={2}>
                원본 언어
              </MKTypography>
              <FormControl sx={{ width: "200px" }}>
                <Select
                  labelId="source-select-label"
                  id="source-select"
                  value={source}
                  onChange={(e) => setSource(e.target.value)}
                  sx={{
                    borderRadius: 20,
                    height: "40px",
                    border: "1px solid #90caf9",
                  }}
                  IconComponent={() => (
                    <ArrowDropDownIcon
                      sx={{ color: "skyblue" }}
                      style={{ marginRight: "10px" }}
                    />
                  )}
                >
                  {languages.map((lang) => (
                    <MenuItem key={lang} value={lang}>
                      <ListItemText
                        primary={
                          <MKTypography
                            variant="body2"
                            sx={{ fontSize: "0.8rem" }}
                          >
                            {languageLabels[lang]}
                          </MKTypography>
                        }
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </MKBox>
            <MKBox display="flex" alignItems="center" ml={4}>
              <MKTypography variant="h6" gutterBottom mr={2}>
                목적 언어
              </MKTypography>
              <FormControl sx={{ width: "200px" }}>
                <Select
                  labelId="target-select-label"
                  id="target-select"
                  value={target}
                  onChange={(e) => setTarget(e.target.value)}
                  sx={{
                    borderRadius: 20,
                    height: "40px",
                    border: "1px solid #90caf9",
                  }}
                  IconComponent={() => (
                    <ArrowDropDownIcon
                      sx={{ color: "skyblue" }}
                      style={{ marginRight: "10px" }}
                    />
                  )}
                >
                  {targetLanguages.map((lang) => (
                    <MenuItem key={lang} value={lang}>
                      <ListItemText
                        primary={
                          <MKTypography
                            variant="body2"
                            sx={{ fontSize: "0.8rem" }}
                          >
                            {languageLabels[lang]}
                          </MKTypography>
                        }
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </MKBox>
          </MKBox>
          <MKBox pt={2} pr={2} pl={2} display="flex" alignItems="center">
            <Grid container spacing={2} alignItems="center" mb={2}>
              <Grid item>
                <MKTypography variant="h6" gutterBottom>
                  높임말 여부
                </MKTypography>
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Switch
                      checked={honorific}
                      onChange={() => setHonorific(!honorific)}
                      color="default"
                    />
                  }
                  label={honorific ? "사용" : "사용 안 함"}
                  labelPlacement="end"
                />
              </Grid>
            </Grid>
          </MKBox>
          <div style={{ position: "relative" }}>
            <textarea
              id="inputTextArea"
              rows={20}
              cols={50}
              placeholder="내용을 입력하세요."
              value={text}
              onChange={(e) => setText(e.target.value)}
              style={{
                width: "calc(100% - 40px)",
                padding: "10px",
                margin: "20px",
                border: "2px solid #ccc",
                borderRadius: "5px",
                resize: "vertical",
                fontFamily: '"Noto Sans", sans-serif',
              }}
            />
          </div>
          {apiResponse && (
            <MKBox
              border={10}
              borderColor="grey.300"
              p={2}
              mt={2}
              sx={{
                height,
                minHeight: "20rem",
                maxHeight: "40rem",
                overflowY: "auto",
                margin: 0,
              }}
            >
              <MKTypography variant="h6" color="success" gutterBottom>
                변환 결과
              </MKTypography>
              {apiResponse && (
                <IconButton
                  aria-label="delete"
                  onClick={handleReset}
                  color="primary"
                  sx={{
                    float: "right",
                    marginRight: "-10px",
                    marginTop: "-40px",
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              )}
              <MKTypography
                variant="subtitle2"
                color="primary"
                component="pre"
                sx={{
                  whiteSpace: "pre-wrap",
                  wordWrap: "break-word",
                  backgroundColor: "transparent",
                  color: "#000",
                  fontSize: "1rem",
                  padding: "1rem",
                  boxSizing: "border-box",
                }}
              >
                {apiResponse.translatedText}
              </MKTypography>
            </MKBox>
          )}
          <MKBox textAlign="center" mt={2}>
            <MKButton
              variant="gradient"
              color="dark"
              onClick={handleExecute}
              fullWidth
            >
              실행
            </MKButton>
          </MKBox>
          {loading && (
            <MKBox textAlign="center" mt={2}>
              <CircularProgress />
            </MKBox>
          )}
          <Slide direction="down" in={error} unmountOnExit>
            <MKBox position="absolute" top="0.5rem" left={0} width="100%">
              <MKAlert
                width="100%"
                mx="auto"
                color="error"
                sx={{
                  minHeight: "3.5rem !important",
                  py: 1,
                  justifyContent: "center",
                }}
              >
                <MKTypography
                  variant="body2"
                  color="white"
                  fontWeight="regular"
                >
                  {errorMessage}
                </MKTypography>
              </MKAlert>
            </MKBox>
          </Slide>
          <Slide direction="down" in={success} unmountOnExit>
            <MKBox position="absolute" top="0.5rem" left={0} width="100%">
              <MKAlert
                width="100%"
                mx="auto"
                color="success"
                sx={{
                  minHeight: "3.5rem !important",
                  py: 1,
                  justifyContent: "center",
                }}
              >
                <MKTypography
                  variant="body2"
                  color="white"
                  fontWeight="regular"
                >
                  변환이 성공적으로 완료되었습니다.
                </MKTypography>
              </MKAlert>
            </MKBox>
          </Slide>
        </MKBox>
      </MKBox>
    </MKBox>
  );
}

View.defaultProps = {
  height: "auto",
};

View.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  height: PropTypes.string,
};

export default View;
