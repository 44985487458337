/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Sections components
import BaseLayout from "pages/BaseLayout";
import View from "pages/View";

// Features page components
import SpeechContent from "pages/Function/Speech/content";

// Features page components code
import SpeechCode from "pages/Function/Speech/content/code";

// Translation
import { useTranslation } from "react-i18next";

function Features() {
  const { t } = useTranslation();
  return (
    <BaseLayout
      title={t(`function.speech.TITLE`)}
      breadcrumb={[
        { label: "Page Sections", route: "/ai/speech/function" },
        { label: t(`function.speech.TITLE`) },
      ]}
    >
      <View title="" code={SpeechCode}>
        <SpeechContent />
      </View>
    </BaseLayout>
  );
}

export default Features;
