/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultInfoCard from "coursemos/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "coursemos/Cards/BlogCards/CenteredBlogCard";

import AnalysisCard from "assets/images/intro/analysis-card.jpg";

// Translation
import { useTranslation } from "react-i18next";

function Information() {
  const { t } = useTranslation();
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="widgets"
                    title={t(`intro.analysis.information.ITEMS1.TITLE`)}
                    description={t(
                      `intro.analysis.information.ITEMS1.CONTENT1`,
                    )}
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="assistant"
                    title={t(`intro.analysis.information.ITEMS2.TITLE`)}
                    description={t(
                      `intro.analysis.information.ITEMS2.CONTENT1`,
                    )}
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={AnalysisCard}
              title={t(`intro.analysis.information.ITEMS3.TITLE`)}
              description={
                <>
                  {t(`intro.analysis.information.ITEMS3.CONTENT1`)}
                  <br />
                  {t(`intro.analysis.information.ITEMS3.CONTENT2`)}
                  <br />
                  {t(`intro.analysis.information.ITEMS3.CONTENT3`)}
                </>
              }
              action={{
                type: "internal",
                route: "/bigdata/analysis/function",
                color: "info",
                label: "find out more",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
