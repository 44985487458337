/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import PropTypes from "prop-types";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

// Translation
import { useTranslation } from "react-i18next";

const tableData = [
  {
    label: "function.ocr.table.BODY.ITEM1.CONTENT1",
    content1: "function.ocr.table.BODY.ITEM1.CONTENT2",
  },
  {
    label: "function.ocr.table.BODY.ITEM2.CONTENT1",
    content1: {
      line1: "function.ocr.table.BODY.ITEM2.CONTENT2",
      line2: "function.ocr.table.BODY.ITEM2.CONTENT3",
    },
  },
  {
    label: "function.ocr.table.BODY.ITEM3.CONTENT1",
    content1: "function.ocr.table.BODY.ITEM3.CONTENT2",
  },
  {
    label: "function.ocr.table.BODY.ITEM4.CONTENT1",
    content1: {
      line1: "function.ocr.table.BODY.ITEM4.CONTENT2",
      line2: "function.ocr.table.BODY.ITEM4.CONTENT3",
    },
  },
  {
    label: "function.ocr.table.BODY.ITEM5.CONTENT1",
    content1: "function.ocr.table.BODY.ITEM5.CONTENT2",
  },
  {
    label: "function.ocr.table.BODY.ITEM6.CONTENT1",
    content1: "function.ocr.table.BODY.ITEM6.CONTENT2",
  },
];

const TableHeaderCell = ({ children }) => (
  <TableCell
    align="center"
    style={{
      fontWeight: "bold",
      minWidth: "250px",
      borderRight: "1px solid #eee",
    }}
  >
    {children}
  </TableCell>
);

TableHeaderCell.propTypes = {
  children: PropTypes.node.isRequired,
};

const TableCellContent = ({ children }) => (
  <TableCell align="center" style={{ borderRight: "1px solid #eee" }}>
    {children}
  </TableCell>
);

TableCellContent.propTypes = {
  children: PropTypes.node.isRequired,
};

function Tables() {
  const { t } = useTranslation();
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          <TableRow>
            <TableHeaderCell>
              {t(`function.ocr.table.HEAD.TITLE1`)}
            </TableHeaderCell>
            <TableHeaderCell>
              {t(`function.ocr.table.HEAD.TITLE2`)}
            </TableHeaderCell>
          </TableRow>
          {tableData.map((row, index) => (
            <TableRow key={index}>
              <TableCellContent>{t(row.label)}</TableCellContent>
              {typeof row.content1 === "object" ? (
                <TableCellContent key={index}>
                  {Object.values(row.content1).map((item, i) => (
                    <span key={i}>
                      {t(item)}
                      <br />
                    </span>
                  ))}
                </TableCellContent>
              ) : (
                <TableCellContent>{t(row.content1)}</TableCellContent>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default Tables;
