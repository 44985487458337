/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import TTSCard from "assets/images/intro/tts-card.jpg";
import STTCard from "assets/images/intro/stt-card.jpg";
import SummaryCard from "assets/images/intro/summary-card.jpg";
import SpeechCard from "assets/images/intro/speech-card.jpg";
import ChatbotCard from "assets/images/intro/chatbot-card.jpg";
import OCRCard from "assets/images/intro/ocr-card.jpg";
import SentimentCard from "assets/images/intro/sentiment-card.jpg";
import SMSCard from "assets/images/intro/sms-card.jpg";
import AnalysisCard from "assets/images/intro/analysis-card.jpg";
import SearchCard from "assets/images/intro/search-card.jpg";
import TensorFlowCard from "assets/images/intro/tensorflow-card.jpg";
import TranslateCard from "assets/images/intro/translate-card.jpg";

export default [
  {
    title: "home.sections.data.designBlocksData.AI.TITLE",
    description: "home.sections.data.designBlocksData.AI.DES",
    items: [
      {
        image: TTSCard,
        name: "home.sections.data.designBlocksData.AI.ITEMS1.NAME",
        subname: "home.sections.data.designBlocksData.AI.ITEMS1.SUBNAME",
        route: "/ai/tts/intro",
      },
      {
        image: STTCard,
        name: "home.sections.data.designBlocksData.AI.ITEMS2.NAME",
        subname: "home.sections.data.designBlocksData.AI.ITEMS2.SUBNAME",
        route: "/ai/stt/intro",
      },
      {
        image: SummaryCard,
        name: "home.sections.data.designBlocksData.AI.ITEMS3.NAME",
        subname: "home.sections.data.designBlocksData.AI.ITEMS3.SUBNAME",
        route: "/ai/summary/intro",
      },
      {
        image: SpeechCard,
        name: "home.sections.data.designBlocksData.AI.ITEMS4.NAME",
        subname: "home.sections.data.designBlocksData.AI.ITEMS4.SUBNAME",
        route: "/ai/speech/intro",
      },
      {
        image: ChatbotCard,
        name: "home.sections.data.designBlocksData.AI.ITEMS5.NAME",
        subname: "home.sections.data.designBlocksData.AI.ITEMS5.SUBNAME",
        route: "/ai/chatbot/intro",
      },
      {
        image: OCRCard,
        name: "home.sections.data.designBlocksData.AI.ITEMS6.NAME",
        subname: "home.sections.data.designBlocksData.AI.ITEMS6.SUBNAME",
        route: "/ai/ocr/intro",
      },
      {
        image: SentimentCard,
        name: "home.sections.data.designBlocksData.AI.ITEMS7.NAME",
        subname: "home.sections.data.designBlocksData.AI.ITEMS7.SUBNAME",
        route: "/ai/sentiment/intro",
      },
      {
        image: TranslateCard,
        name: "home.sections.data.designBlocksData.AI.ITEMS8.NAME",
        subname: "home.sections.data.designBlocksData.AI.ITEMS8.SUBNAME",
        route: "/ai/translate/intro",
      },
    ],
  },
  {
    title: "home.sections.data.designBlocksData.NOTI.TITLE",
    description: "home.sections.data.designBlocksData.NOTI.DES",
    items: [
      {
        image: SMSCard,
        name: "home.sections.data.designBlocksData.NOTI.ITEMS1.NAME",
        subname: "home.sections.data.designBlocksData.NOTI.ITEMS1.SUBNAME",
        route: "/notification/sms/intro",
      },
    ],
  },
  {
    title: "home.sections.data.designBlocksData.BIG.TITLE",
    description: "home.sections.data.designBlocksData.BIG.DES",
    items: [
      {
        image: AnalysisCard,
        name: "home.sections.data.designBlocksData.BIG.ITEMS1.NAME",
        subname: "home.sections.data.designBlocksData.BIG.ITEMS1.SUBNAME",
        route: "/bigdata/analysis/intro",
      },
      {
        image: SearchCard,
        name: "home.sections.data.designBlocksData.BIG.ITEMS2.NAME",
        subname: "home.sections.data.designBlocksData.BIG.ITEMS2.SUBNAME",
        route: "/bigdata/search/intro",
      },
      {
        image: TensorFlowCard,
        name: "home.sections.data.designBlocksData.BIG.ITEMS3.NAME",
        subname: "home.sections.data.designBlocksData.BIG.ITEMS3.SUBNAME",
        route: "/bigdata/tensorflow/intro",
      },
    ],
  },
];
