import * as React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import MKTypography from "components/MKTypography";
import MKAlert from "components/MKAlert";

export default function BasicSelect() {
  const [selectedOption, setSelectedOption] = React.useState("");
  const [inputTitle, setInputTitle] = React.useState("");
  const [inputContent, setInputContent] = React.useState("");
  const [inputEmail, setInputEmail] = React.useState("");
  const [inputPhone, setInputPhone] = React.useState("");
  const [showAlert, setShowAlert] = React.useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = React.useState(false);

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleTitle = (event) => {
    setInputTitle(event.target.value);
  };

  const handleContent = (event) => {
    setInputContent(event.target.value);
  };

  const handleEmail = (event) => {
    setInputEmail(event.target.value);
  };

  const handlePhone = (event) => {
    setInputPhone(event.target.value);
  };
  const handleSubmit = async () => {
    if (!selectedOption || !inputTitle || !inputContent || !inputEmail) {
      setShowAlert(true);
      return;
    }

    try {
      const requestBody = {
        selected_option: selectedOption,
        title: inputTitle,
        content: inputContent,
        email: inputEmail,
        phone_number: inputPhone,
      };
      const response = await fetch(
        "https://develop.coursemos.co.kr/dev/question",
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        },
      );
      if (response.ok) {
        console.log("saved successfully");
        // const responseData = await response.json();
        setShowSuccessAlert(true); // 성공 메시지 표시
        setShowAlert(false); // 경고 메시지 숨기기

        // 필요한 경우 폼 리셋
        setSelectedOption("");
        setInputTitle("");
        setInputContent("");
        setInputEmail("");
        setInputPhone("");
      } else {
        // 오류 처리
        console.error("Error submitting data");
      }
    } catch (error) {
      console.error("Network error:", error);
    }
  };

  React.useEffect(() => {
    const handleClickOutside = () => {
      setShowSuccessAlert(false);
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  return (
    <Box sx={{ minWidth: 120 }}>
      {showAlert && (
        <Alert
          severity="error"
          onClose={() => setShowAlert(false)}
          sx={{ mb: 2 }}
        >
          모든 필수 항목을 입력해주세요.
        </Alert>
      )}
      {showSuccessAlert && (
        <MKAlert
          color="primary"
          onClose={() => setShowSuccessAlert(false)}
          sx={{ mb: 2 }}
        >
          문의가 성공적으로 제출되었습니다.
        </MKAlert>
      )}
      <FormControl fullWidth sx={{ mb: 2 }}>
        <MKTypography variant="body2" color="text" mb={1}>
          문의 유형 (필수)
        </MKTypography>
        <Select
          id="type"
          value={selectedOption}
          onChange={handleSelectChange}
          style={{ height: "40px" }}
        >
          <MenuItem value="AI">AI</MenuItem>
          <MenuItem value="TTS">Text-to-Speech</MenuItem>
          <MenuItem value="STT">Speech-to-Text</MenuItem>
          <MenuItem value="Summary">Summary</MenuItem>
          <MenuItem value="Speech">Speech</MenuItem>
          <MenuItem value="Sentiment">Sentiment Analysis</MenuItem>
          <MenuItem value="Chatbot">Chatbot</MenuItem>
          <MenuItem value="OCR">Optical Character Recognition</MenuItem>
          <MenuItem value="Notification">Notification</MenuItem>
          <MenuItem value="SMS">SMS</MenuItem>
          <MenuItem value="Bigdata">Big Data</MenuItem>
          <MenuItem value="Analysis">Data Analysis</MenuItem>
          <MenuItem value="SearchEngine">Search Engine Service</MenuItem>
          <MenuItem value="TensorFlow">TensorFlow</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <MKTypography variant="body2" color="text" mb={1}>
          제목 (필수)
        </MKTypography>
        <TextField
          id="title"
          value={inputTitle}
          onChange={handleTitle}
          variant="outlined"
          required
        />
      </FormControl>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <MKTypography variant="body2" color="text" mb={1}>
          내용 (필수)
        </MKTypography>
        <TextField
          id="content"
          value={inputContent}
          onChange={handleContent}
          multiline
          rows={8}
          variant="outlined"
          required
        />
      </FormControl>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <MKTypography variant="body2" color="text" mb={1}>
          이메일주소 (필수)
        </MKTypography>
        <TextField
          id="email"
          value={inputEmail}
          onChange={handleEmail}
          variant="outlined"
          required
        />
      </FormControl>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <MKTypography variant="body2" color="text" mb={1}>
          휴대 전화번호 (선택)
        </MKTypography>
        <TextField
          id="number"
          value={inputPhone}
          onChange={handlePhone}
          variant="outlined"
          placeholder="대시(-)를 제외한 후 숫자만 입력해 주십시오"
        />
      </FormControl>
      <Grid container item justifyContent="center" xs={12} mt={5} mb={2}>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          style={{ color: "white" }}
        >
          문의하기
        </Button>
      </Grid>
    </Box>
  );
}
