import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import MKBox from "components/MKBox";
import MKAlert from "components/MKAlert";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import ArrowDropDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useTranslation } from "react-i18next";

import {
  Grid,
  AppBar,
  Tabs,
  Tab,
  IconButton,
  CircularProgress,
  FormControl,
  Select,
  MenuItem,
  Slide,
  Checkbox,
  ListItemText,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

function View({ children, title, height, ...rest }) {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [apiResponse, setApiResponse] = useState("");
  const [content, setContent] = useState("");
  const [audioElement, setAudioElement] = useState(null);
  const [selectedVoice, setSelectedVoice] = useState("nara");
  const [selectedOptions, setSelectedOptions] = useState({
    languages: ["ko"],
    genders: ["woman"],
  });

  const languages = ["ko", "en", "ch", "ja"];
  const genders = ["woman", "man"];

  const languageLabels = {
    ko: "한국어",
    en: "영어",
    ch: "중국어",
    ja: "일본어",
  };
  const genderLabels = {
    woman: "여성",
    man: "남성",
  };

  const voices = [
    {
      value: "nara",
      lang: "ko",
      gender: "woman",
      label: "아라",
      desc: "#활기찬 #차분한",
    },
    {
      value: "nara_call",
      lang: "ko",
      gender: "woman",
      label: "아라(상담원)",
      desc: "#활기찬 #차분한",
    },
    {
      value: "nminyoung",
      lang: "ko",
      gender: "woman",
      label: "민영",
      desc: "#차분한 #싹싹한",
    },
    {
      value: "nyejin",
      lang: "ko",
      gender: "woman",
      label: "예진",
      desc: "#신뢰가는 #친절한",
    },
    {
      value: "mijin",
      lang: "ko",
      gender: "woman",
      label: "미진",
      desc: "#활기찬 #열정적인",
    },
    {
      value: "jinho",
      lang: "ko",
      gender: "man",
      label: "진호",
      desc: "#활기찬 #차분한",
    },
    {
      value: "clara",
      lang: "en",
      gender: "woman",
      label: "클라라",
      desc: "#차분한 #싹싹한",
    },
    {
      value: "matt",
      lang: "en",
      gender: "man",
      label: "매트",
      desc: "#활기찬 #차분한",
    },
    {
      value: "shinji",
      lang: "ja",
      gender: "man",
      label: "신지",
      desc: "#매력적인 #개성있는",
    },
    {
      value: "meimei",
      lang: "ch",
      gender: "woman",
      label: "메이메이",
      desc: "#매력적인 #새침한",
    },
  ];

  const countCharacters = (text) => {
    return text.length;
  };
  const handleTabType = (event, newValue) => setActiveTab(newValue);

  const handleReset = () => {
    setApiResponse("");
    setContent("");
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setSelectedOptions((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleExecute = async () => {
    if (content.trim() !== "") {
      setLoading(true);
      try {
        const formData = new FormData();
        formData.append("speaker", selectedVoice);
        formData.append("text", content);
        formData.append("action", "tts");
        const response = await axios.post(process.env.REACT_APP_API, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
          responseType: "arraybuffer",
        });

        if (audioElement) {
          audioElement.pause(); // 재생 중인 오디오를 일시 정지
          URL.revokeObjectURL(audioElement.src); // URL 해제
        }
        const blob = new Blob([response.data], { type: "audio/mpeg" });
        const audioUrl = URL.createObjectURL(blob);
        const audio = new Audio(audioUrl);
        setAudioElement(audio);
        audio.play();
        setSuccess(true);
      } catch (error) {
        setErrorMessage("변환에 실패하였습니다.");
        setError(true);
      } finally {
        setLoading(false);
      }
    } else {
      setErrorMessage("내용을 입력하세요.");
      setError(true);
    }
  };
  useEffect(() => {
    if (success) {
      setTimeout(() => setSuccess(false), 3000);
    }
    if (error) {
      setTimeout(() => setError(false), 3000);
    }
  }, [success, error]);

  return (
    <MKBox
      width="100%"
      position="relative"
      borderRadius="xl"
      shadow="lg"
      mb={12}
      sx={{ overflow: "hidden" }}
      {...rest}
    >
      <MKBox
        px={3}
        sx={{
          borderBottom: ({ borders: { borderWidth, borderColor } }) =>
            `${borderWidth[1]} solid ${borderColor}`,
        }}
      >
        <Grid container spacing={2} justifyContent="space-between" py={1}>
          <Grid item xs={12} lg={3}>
            <MKTypography variant="body1" pt={0.5}>
              {title}
            </MKTypography>
          </Grid>
          <Grid item xs={12} lg={3}>
            <AppBar position="static">
              <Tabs value={activeTab} onChange={handleTabType}>
                <Tab
                  icon={
                    <MKBox
                      component="i"
                      color="dark"
                      mr={1.25}
                      sx={{ fontSize: ({ typography: { size } }) => size.sm }}
                      className="fas fa-desktop"
                    />
                  }
                  label={t("tabs.DETAILS")}
                />
                <Tab
                  icon={
                    <MKBox
                      component="i"
                      color="dark"
                      mr={1.25}
                      sx={{ fontSize: ({ typography: { size } }) => size.sm }}
                      className="fas fa-user-astronaut"
                    />
                  }
                  label={t("tabs.EXPERIENCE")}
                />
              </Tabs>
            </AppBar>
          </Grid>
        </Grid>
      </MKBox>
      <MKBox display={activeTab === 0 ? "block" : "none"}>
        <MKBox width="100%" p={3}>
          <MKBox
            bgColor="grey-100"
            width="100%"
            height={height}
            maxHeight="40rem"
            borderRadius="xl"
            sx={{ overflowX: "hidden", overflowY: "scroll" }}
          >
            {children}
          </MKBox>
        </MKBox>
      </MKBox>
      <MKBox display={activeTab === 1 ? "block" : "none"} p={3}>
        <MKBox
          bgColor="grey-100"
          position="relative"
          width="100%"
          borderRadius="xl"
          sx={{ overflow: "hidden" }}
        >
          <MKBox pt={2} pr={2} pl={2} display="flex" alignItems="center">
            <Grid container spacing={2} alignItems="center" mb={2}>
              <Grid item>
                <MKTypography variant="h6" gutterBottom>
                  언어
                </MKTypography>
              </Grid>
              <Grid item>
                <FormControl sx={{ width: "100%" }}>
                  <Select
                    labelId="language-select-label"
                    id="language-select"
                    multiple
                    name="languages"
                    value={selectedOptions.languages}
                    onChange={handleChange}
                    renderValue={(selected) =>
                      selected.map((value) => languageLabels[value]).join(", ")
                    }
                    sx={{
                      borderRadius: 20,
                      height: "40px",
                      border: "1px solid #90caf9",
                      marginBottom: "5px",
                    }}
                    IconComponent={() => (
                      <ArrowDropDownIcon
                        sx={{ color: "skyblue" }}
                        style={{ marginRight: "10px" }}
                      />
                    )}
                  >
                    {languages.map((lang) => (
                      <MenuItem key={lang} value={lang}>
                        <Checkbox
                          checked={selectedOptions.languages.indexOf(lang) > -1}
                        />
                        <ListItemText
                          primary={
                            <MKTypography
                              variant="body2"
                              sx={{ fontSize: "0.8rem" }}
                            >
                              {languageLabels[lang]}
                            </MKTypography>
                          }
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <MKTypography variant="h6" pl={2} gutterBottom>
                  성별
                </MKTypography>
              </Grid>
              <Grid item>
                <FormControl>
                  <Select
                    labelId="gender-select-label"
                    id="gender-select"
                    multiple
                    name="genders"
                    value={selectedOptions.genders}
                    onChange={handleChange}
                    renderValue={(selected) =>
                      selected.map((value) => genderLabels[value]).join(", ")
                    }
                    sx={{
                      borderRadius: 20,
                      height: "40px",
                      border: "1px solid #90caf9",
                      marginBottom: "5px",
                    }}
                    IconComponent={() => (
                      <ArrowDropDownIcon
                        sx={{ color: "skyblue" }}
                        style={{ marginRight: "10px" }}
                      />
                    )}
                  >
                    {genders.map((gen) => (
                      <MenuItem key={gen} value={gen}>
                        <Checkbox
                          checked={selectedOptions.genders.indexOf(gen) > -1}
                        />
                        <ListItemText
                          primary={
                            <MKTypography
                              variant="body2"
                              sx={{ fontSize: "0.8rem" }}
                            >
                              {genderLabels[gen]}
                            </MKTypography>
                          }
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </MKBox>
          <MKBox py={2} pr={2} pl={2} display="flex" alignItems="center">
            <FormControl component="fieldset">
              <MKTypography variant="h6" gutterBottom>
                보이스
              </MKTypography>
              <RadioGroup
                aria-label="voice"
                name="voice"
                value={selectedVoice}
                onChange={(e) => setSelectedVoice(e.target.value)}
                style={{ flexDirection: "row" }}
              >
                {voices
                  .filter(
                    (voice) =>
                      selectedOptions.languages.includes(voice.lang) &&
                      selectedOptions.genders.includes(voice.gender),
                  )
                  .map((voice) => (
                    <FormControlLabel
                      key={voice.value}
                      value={voice.value}
                      control={<Radio />}
                      sx={{
                        marginLeft: "5px",
                        marginTop: "7px",
                      }}
                      label={
                        <Grid
                          container
                          alignItems="center"
                          sx={{ width: "200px" }}
                        >
                          <span>{voice.label}</span>
                          <span
                            style={{
                              fontSize: "0.8rem",
                              color: "gray",
                              marginLeft: "10px",
                            }}
                          >
                            {" " + voice.desc}
                          </span>
                        </Grid>
                      }
                      onChange={() => setSelectedVoice(voice.value)}
                    />
                  ))}
              </RadioGroup>
            </FormControl>
          </MKBox>
          <div style={{ position: "relative" }}>
            <textarea
              id="inputTextArea"
              rows={20}
              cols={50}
              placeholder="내용을 입력하세요."
              value={content}
              onChange={(e) => setContent(e.target.value)}
              style={{
                width: "calc(100% - 40px)",
                padding: "10px",
                margin: "20px",
                border: "2px solid #ccc",
                borderRadius: "5px",
                resize: "vertical",
                fontFamily: '"Noto Sans", sans-serif',
              }}
            />
            <MKTypography
              variant="subtitle2"
              style={{
                position: "absolute",
                bottom: "-1px",
                right: "30px",
                color: countCharacters(content) > 200 ? "#f00" : "#0000FF", // 200자 넘어가면 빨간색, 그렇지 않으면 검정색
              }}
            >
              글자 수: {countCharacters(content)} / 200
            </MKTypography>
          </div>
          {apiResponse && (
            <MKBox
              border={10}
              borderColor="grey.300"
              p={2}
              mt={2}
              sx={{
                height,
                minHeight: "20rem",
                maxHeight: "40rem",
                overflowY: "auto",
                margin: 0,
              }}
            >
              <MKTypography variant="h6" color="success" gutterBottom>
                변환 결과
              </MKTypography>
              {apiResponse && (
                <IconButton
                  aria-label="delete"
                  onClick={handleReset}
                  color="primary"
                  sx={{
                    float: "right",
                    marginRight: "-10px",
                    marginTop: "-40px",
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              )}
              <MKTypography
                variant="subtitle2"
                color="primary"
                component="pre"
                sx={{
                  whiteSpace: "pre-wrap",
                  wordWrap: "break-word",
                  backgroundColor: "transparent",
                  color: "#000",
                  fontSize: "1rem",
                  padding: "1rem",
                  boxSizing: "border-box",
                }}
              >
                {apiResponse}
              </MKTypography>
            </MKBox>
          )}
          <MKBox textAlign="center" mt={2}>
            <MKButton
              variant="gradient"
              color="dark"
              onClick={handleExecute}
              fullWidth
            >
              실행
            </MKButton>
          </MKBox>
          {loading && (
            <MKBox textAlign="center" mt={2}>
              <CircularProgress />
            </MKBox>
          )}
          <Slide direction="down" in={error} unmountOnExit>
            <MKBox position="absolute" top="0.5rem" left={0} width="100%">
              <MKAlert
                width="100%"
                mx="auto"
                color="error"
                sx={{
                  minHeight: "3.5rem !important",
                  py: 1,
                  justifyContent: "center",
                }}
              >
                <MKTypography
                  variant="body2"
                  color="white"
                  fontWeight="regular"
                >
                  {errorMessage}
                </MKTypography>
              </MKAlert>
            </MKBox>
          </Slide>
          <Slide direction="down" in={success} unmountOnExit>
            <MKBox position="absolute" top="0.5rem" left={0} width="100%">
              <MKAlert
                width="100%"
                mx="auto"
                color="success"
                sx={{
                  minHeight: "3.5rem !important",
                  py: 1,
                  justifyContent: "center",
                }}
              >
                <MKTypography
                  variant="body2"
                  color="white"
                  fontWeight="regular"
                >
                  변환이 성공적으로 완료되었습니다.
                </MKTypography>
              </MKAlert>
            </MKBox>
          </Slide>
        </MKBox>
      </MKBox>
    </MKBox>
  );
}

View.defaultProps = {
  height: "auto",
};

View.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  height: PropTypes.string,
};

export default View;
