import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Slide from "@mui/material/Slide";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import CircularProgress from "@mui/material/CircularProgress";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import MKBox from "components/MKBox";
import MKAlert from "components/MKAlert";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import { useTranslation } from "react-i18next";

function View({ children, title, height, ...rest }) {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [apiResponse, setApiResponse] = useState("");
  const [content, setContent] = useState("");

  const [type, setType] = useState("normal");
  const [nori, setNori] = useState("no");
  const [staticCount, setStaticCount] = useState("no");

  const labelWidth = "140px";

  const countCharacters = (text) => {
    return text.length;
  };
  const handleTypeChange = (value) => {
    setType(value);
  };
  const handleNoriChange = (value) => {
    setNori(value);
  };
  const handleStaticChange = (value) => {
    setStaticCount(value);
  };

  const handleTabType = (event, newValue) => setActiveTab(newValue);

  const handleReset = () => {
    setApiResponse("");
    setContent("");
  };

  const handleExecute = async () => {
    if (content.trim() !== "") {
      setLoading(true);
      try {
        const params = new URLSearchParams();
        params.append("type", type);
        params.append("nori", nori);
        params.append("static", staticCount);
        params.append("content", content);
        params.append("action", "analysis");
        const response = await axios.post(process.env.REACT_APP_API, params, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "application/json",
          },
        });
        if (response.data) {
          setApiResponse(response.data);
          setSuccess(true);
        } else if (response.data.error) {
          const errorCode = response.data.error.errorCode;
          switch (errorCode) {
            case "E100":
              setErrorMessage(
                "문장의 길이가 너무 짧거나 유효한 문장이 아닙니다.",
              );
              break;
            case "E003":
              setErrorMessage("문장의 길이가 초과 하였습니다.");
              break;
            default:
              setErrorMessage("변환에 실패하였습니다.");
              break;
          }
          setError(true);
        }
      } catch (error) {
        setErrorMessage("변환에 실패하였습니다.");
        setError(true);
      } finally {
        setLoading(false);
      }
    } else {
      setErrorMessage("내용을 입력하세요.");
      setError(true);
    }
  };
  useEffect(() => {
    if (success) {
      setTimeout(() => setSuccess(false), 3000);
    }
    if (error) {
      setTimeout(() => setError(false), 3000);
    }
  }, [success, error]);

  return (
    <MKBox
      width="100%"
      position="relative"
      borderRadius="xl"
      shadow="lg"
      mb={12}
      sx={{ overflow: "hidden" }}
      {...rest}
    >
      <MKBox
        px={3}
        sx={{
          borderBottom: ({ borders: { borderWidth, borderColor } }) =>
            `${borderWidth[1]} solid ${borderColor}`,
        }}
      >
        <Grid container spacing={2} justifyContent="space-between" py={1}>
          <Grid item xs={12} lg={3}>
            <MKTypography variant="body1" pt={0.5}>
              {title}
            </MKTypography>
          </Grid>
          <Grid item xs={12} lg={3}>
            <AppBar position="static">
              <Tabs value={activeTab} onChange={handleTabType}>
                <Tab
                  icon={
                    <MKBox
                      component="i"
                      color="dark"
                      mr={1.25}
                      sx={{ fontSize: ({ typography: { size } }) => size.sm }}
                      className="fas fa-desktop"
                    />
                  }
                  label={t("tabs.DETAILS")}
                />
                <Tab
                  icon={
                    <MKBox
                      component="i"
                      color="dark"
                      mr={1.25}
                      sx={{ fontSize: ({ typography: { size } }) => size.sm }}
                      className="fas fa-user-astronaut"
                    />
                  }
                  label={t("tabs.EXPERIENCE")}
                />
              </Tabs>
            </AppBar>
          </Grid>
        </Grid>
      </MKBox>
      <MKBox display={activeTab === 0 ? "block" : "none"}>
        <MKBox width="100%" p={3}>
          <MKBox
            bgColor="grey-100"
            width="100%"
            height={height}
            maxHeight="40rem"
            borderRadius="xl"
            sx={{ overflowX: "hidden", overflowY: "scroll" }}
          >
            {children}
          </MKBox>
        </MKBox>
      </MKBox>
      <MKBox display={activeTab === 1 ? "block" : "none"} p={3}>
        <MKBox
          bgColor="grey-100"
          position="relative"
          width="100%"
          borderRadius="xl"
          sx={{ overflow: "hidden" }}
        >
          <MKBox pt={2} pr={2} pl={2} display="flex" alignItems="center">
            <MKTypography
              variant="h6"
              style={{ marginRight: "10px", width: labelWidth }}
            >
              형태소 상세 처리
            </MKTypography>
            <FormGroup
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginLeft: "25px",
              }}
            >
              <div style={{ width: labelWidth }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={type === "normal"}
                      onChange={() => handleTypeChange("normal")}
                    />
                  }
                  label="일반 결과 확인"
                />
              </div>
              <div style={{ width: labelWidth }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={type === "detail"}
                      onChange={() => handleTypeChange("detail")}
                    />
                  }
                  label="상세 결과 확인"
                />
              </div>
            </FormGroup>
          </MKBox>
          <MKBox pr={2} pl={2} display="flex" alignItems="center">
            <MKTypography
              variant="h6"
              style={{ marginRight: "10px", width: labelWidth }}
            >
              합성어 어근 분리
            </MKTypography>
            <FormGroup
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginLeft: "25px",
              }}
            >
              <div style={{ width: labelWidth }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={nori === "no"}
                      onChange={() => handleNoriChange("no")}
                    />
                  }
                  label="예"
                />
              </div>
              <div style={{ width: labelWidth }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={nori === "yes"}
                      onChange={() => handleNoriChange("yes")}
                    />
                  }
                  label="아니오"
                />
              </div>
            </FormGroup>
          </MKBox>
          <MKBox pr={2} pl={2} display="flex" alignItems="center">
            <MKTypography
              variant="h6"
              style={{ marginRight: "10px", width: labelWidth }}
            >
              형태소 카운트
            </MKTypography>
            <FormGroup
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginLeft: "25px",
              }}
            >
              <div style={{ width: labelWidth }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={staticCount === "yes"}
                      onChange={() => handleStaticChange("yes")}
                    />
                  }
                  label="예"
                />
              </div>
              <div style={{ width: labelWidth }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={staticCount === "no"}
                      onChange={() => handleStaticChange("no")}
                    />
                  }
                  label="아니오"
                />
              </div>
            </FormGroup>
          </MKBox>
          <div style={{ position: "relative" }}>
            <textarea
              id="inputTextArea"
              rows={20}
              cols={50}
              placeholder="내용을 입력하세요."
              value={content}
              onChange={(e) => setContent(e.target.value)}
              style={{
                width: "calc(100% - 40px)",
                padding: "10px",
                margin: "20px",
                border: "2px solid #ccc",
                borderRadius: "5px",
                resize: "vertical",
                fontFamily: '"Noto Sans", sans-serif',
              }}
            />
            <MKTypography
              variant="subtitle2"
              style={{
                position: "absolute",
                bottom: "-1px",
                right: "30px",
                color: countCharacters(content) > 2000 ? "#f00" : "#0000FF", // 2000자 넘어가면 빨간색, 그렇지 않으면 검정색
              }}
            >
              글자 수: {countCharacters(content)} / 2000
            </MKTypography>
          </div>
          {apiResponse && (
            <MKBox
              border={10}
              borderColor="grey.300"
              p={2}
              mt={2}
              sx={{
                height,
                minHeight: "20rem",
                maxHeight: "40rem",
                overflowY: "auto",
                margin: 0,
              }}
            >
              <MKTypography variant="h6" color="success" gutterBottom>
                변환 결과
              </MKTypography>
              {apiResponse && (
                <IconButton
                  aria-label="delete"
                  onClick={handleReset}
                  color="primary"
                  sx={{
                    float: "right",
                    marginRight: "-10px",
                    marginTop: "-40px",
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              )}
              <MKBox
                sx={{
                  backgroundColor: "#f9f9f9",
                  borderRadius: "5px",
                  padding: "1rem",
                }}
              >
                <MKTypography variant="h5" gutterBottom>
                  형태소 분석
                </MKTypography>
                {apiResponse.tokens &&
                  apiResponse.tokens.map((token, index) => (
                    <MKBox
                      key={index}
                      sx={{
                        marginBottom: "1rem",
                        padding: "1rem",
                        backgroundColor: "#f9f9f9",
                        borderRadius: "8px",
                        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <MKTypography variant="body2" color="info">
                        <strong>Token:</strong> {token.token}
                      </MKTypography>
                      <MKTypography variant="body2" color="secondary">
                        <strong>Start Offset:</strong> {token.start_offset}
                      </MKTypography>
                      <MKTypography variant="body2" color="secondary">
                        <strong>End Offset:</strong> {token.end_offset}
                      </MKTypography>
                      <MKTypography variant="body2" color="secondary">
                        <strong>Type:</strong> {token.type}
                      </MKTypography>
                      <MKTypography variant="body2" color="secondary">
                        <strong>Position:</strong> {token.position}
                      </MKTypography>
                    </MKBox>
                  ))}
                {apiResponse.detail &&
                  apiResponse.detail.tokenizer &&
                  apiResponse.detail.tokenizer.tokens &&
                  apiResponse.detail.tokenizer.tokens.map((token, index) => (
                    <MKBox
                      key={index}
                      sx={{
                        marginBottom: "1rem",
                        padding: "1rem",
                        backgroundColor: "#f9f9f9",
                        borderRadius: "8px",
                        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <MKTypography variant="body2" color="info">
                        <strong>Token:</strong> {token.token}
                      </MKTypography>
                      <MKTypography variant="body2" color="secondary">
                        <strong>Start Offset:</strong> {token.start_offset}
                      </MKTypography>
                      <MKTypography variant="body2" color="secondary">
                        <strong>End Offset:</strong> {token.end_offset}
                      </MKTypography>
                      <MKTypography variant="body2" color="secondary">
                        <strong>Type:</strong> {token.type}
                      </MKTypography>
                      <MKTypography variant="body2" color="secondary">
                        <strong>Position:</strong> {token.position}
                      </MKTypography>
                      <MKTypography variant="body2" color="secondary">
                        <strong>Bytes:</strong> {token.bytes}
                      </MKTypography>
                      <MKTypography variant="body2" color="secondary">
                        <strong>Left POS:</strong> {token.leftPOS}
                      </MKTypography>
                      <MKTypography variant="body2" color="secondary">
                        <strong>Morphemes:</strong> {token.morphemes}
                      </MKTypography>
                      <MKTypography variant="body2" color="secondary">
                        <strong>POS Type:</strong> {token.posType}
                      </MKTypography>
                      <MKTypography variant="body2" color="secondary">
                        <strong>Position Length:</strong> {token.positionLength}
                      </MKTypography>
                      <MKTypography variant="body2" color="secondary">
                        <strong>Reading:</strong> {token.reading}
                      </MKTypography>
                      <MKTypography variant="body2" color="secondary">
                        <strong>Right POS:</strong> {token.rightPOS}
                      </MKTypography>
                      <MKTypography variant="body2" color="secondary">
                        <strong>Term Frequency:</strong> {token.termFrequency}
                      </MKTypography>
                    </MKBox>
                  ))}
                {apiResponse.static && (
                  <MKBox>
                    <MKTypography variant="h5" gutterBottom>
                      형태소 카운트
                    </MKTypography>
                    <MKBox
                      sx={{
                        marginBottom: "1rem",
                        padding: "1rem",
                        backgroundColor: "#f5f5f5",
                        borderRadius: "8px",
                        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      {Object.entries(apiResponse.static).map(
                        ([token, count], index) => (
                          <MKTypography
                            key={index}
                            variant="body2"
                            color="secondary"
                            gutterBottom
                          >
                            <strong>{token}:</strong> {count}
                          </MKTypography>
                        ),
                      )}
                    </MKBox>
                  </MKBox>
                )}
              </MKBox>
            </MKBox>
          )}
          <MKBox textAlign="center" mt={2}>
            <MKButton
              variant="gradient"
              color="dark"
              onClick={handleExecute}
              fullWidth
            >
              실행
            </MKButton>
          </MKBox>
          {loading && (
            <MKBox textAlign="center" mt={2}>
              <CircularProgress />
            </MKBox>
          )}
          <Slide direction="down" in={error} unmountOnExit>
            <MKBox position="absolute" top="0.5rem" left={0} width="100%">
              <MKAlert
                width="100%"
                mx="auto"
                color="error"
                sx={{
                  minHeight: "3.5rem !important",
                  py: 1,
                  justifyContent: "center",
                }}
              >
                <MKTypography
                  variant="body2"
                  color="white"
                  fontWeight="regular"
                >
                  {errorMessage}
                </MKTypography>
              </MKAlert>
            </MKBox>
          </Slide>
          <Slide direction="down" in={success} unmountOnExit>
            <MKBox position="absolute" top="0.5rem" left={0} width="100%">
              <MKAlert
                width="100%"
                mx="auto"
                color="success"
                sx={{
                  minHeight: "3.5rem !important",
                  py: 1,
                  justifyContent: "center",
                }}
              >
                <MKTypography
                  variant="body2"
                  color="white"
                  fontWeight="regular"
                >
                  변환이 성공적으로 완료되었습니다.
                </MKTypography>
              </MKAlert>
            </MKBox>
          </Slide>
        </MKBox>
      </MKBox>
    </MKBox>
  );
}

View.defaultProps = {
  height: "auto",
};

View.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  height: PropTypes.string,
};

export default View;
