import React from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";

const MKTextField = ({
  label,
  value,
  onChange,
  fullWidth,
  margin,
  multiline,
  rows,
  style,
  ...props
}) => {
  return (
    <TextField
      label={label}
      value={value}
      onChange={onChange}
      fullWidth={fullWidth}
      margin={margin}
      multiline={multiline}
      rows={rows}
      InputLabelProps={{
        style: {
          paddingTop: "1.1px",
        },
      }}
      inputProps={{
        style: style, // inputProps를 통해 textarea 스타일 설정
      }}
      {...props}
    />
  );
};

MKTextField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
  margin: PropTypes.string,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  style: PropTypes.object, // style prop에 대한 PropTypes 추가
};

MKTextField.defaultProps = {
  fullWidth: true,
  margin: "normal",
  multiline: false,
  rows: 1,
  style: {}, // 기본값 추가
};

export default MKTextField;
