/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

// Translation
import { useTranslation } from "react-i18next";

function Tables() {
  const { t } = useTranslation();
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell
              align="center"
              style={{
                fontWeight: "bold",
                minWidth: "250px",
                borderRight: "1px solid #eee",
              }}
            >
              {t(`function.tts.table.HEAD.TITLE1`)}
            </TableCell>
            <TableCell align="center" style={{ fontWeight: "bold" }}>
              {t(`function.tts.table.HEAD.TITLE2`)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              align="center"
              style={{
                borderRight: "1px solid #eee",
              }}
            >
              {t(`function.tts.table.BODY.ITEM1.CONTENT1`)}
            </TableCell>
            <TableCell align="center">
              {t(`function.tts.table.BODY.ITEM1.CONTENT2`)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              align="center"
              style={{
                borderRight: "1px solid #eee",
              }}
            >
              {t(`function.tts.table.BODY.ITEM2.CONTENT1`)}
            </TableCell>
            <TableCell align="center">
              {t(`function.tts.table.BODY.ITEM2.CONTENT2`)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default Tables;
