/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import OCRTable from "pages/Function/OCR/table";
import FilledInfoCard from "coursemos/Cards/InfoCards/FilledInfoCard";
import OCRImg1 from "assets/images/function/ocr1.jpg";
import OCRImg2 from "assets/images/function/ocr2.jpg";
import OCRImg3 from "assets/images/function/ocr3.jpg";

// Translation
import { useTranslation } from "react-i18next";

const HorizontalLine = (
  <hr
    style={{
      border: "none",
      borderBottom: "1px solid #eee",
      margin: "0 0 16px 0",
    }}
  />
);

function FeaturesOne() {
  const { t } = useTranslation();
  return (
    <MKBox component="section" py={{ xs: 3, md: 12 }}>
      <Container>
        <Grid container alignItems="center">
          <Grid item xs={12} lg={10}>
            <MKBox display="flex" alignItems="center">
              <MKBox
                width="3rem"
                height="3rem"
                variant="gradient"
                bgColor="info"
                color="white"
                coloredShadow="info"
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderRadius="xl"
              >
                <Icon fontSize="small">star</Icon>
              </MKBox>
              <MKTypography variant="h3" pl={2} my={1}>
                {t(`function.ocr.content.ITEMS1.TITLE`)}
              </MKTypography>
            </MKBox>
            {HorizontalLine}
            <MKBox display="flex" alignItems="center">
              <MKBox style={{ width: "50%" }}>
                <MKTypography variant="h5" color="text" mb={2}>
                  {t(`function.ocr.content.ITEMS1.SUBTITLE1`)}
                </MKTypography>
                <MKTypography variant="body2" color="text" mb={4}>
                  {t(`function.ocr.content.ITEMS1.CONTENT1`)}
                </MKTypography>
              </MKBox>
              <MKBox mb={6} style={{ width: "50%" }}>
                <img
                  src={OCRImg1}
                  alt="OCR1"
                  style={{ width: "100%", height: "auto" }}
                />
              </MKBox>
            </MKBox>
            <MKBox display="flex" alignItems="center">
              <MKBox mb={6} style={{ width: "50%" }}>
                <img
                  src={OCRImg2}
                  alt="OCR2"
                  style={{ width: "100%", height: "auto" }}
                />
              </MKBox>
              <MKBox style={{ width: "50%" }} ml={10}>
                <MKTypography variant="h5" color="text" mb={2}>
                  {t(`function.ocr.content.ITEMS1.SUBTITLE2`)}
                </MKTypography>
                <MKTypography variant="body2" color="text" mb={4}>
                  {t(`function.ocr.content.ITEMS1.CONTENT2`)}
                </MKTypography>
              </MKBox>
            </MKBox>
            <MKTypography variant="h5" color="text" mb={2}>
              {t(`function.ocr.content.ITEMS1.SUBTITLE3`)}
            </MKTypography>
            <MKTypography variant="body2" color="text" mb={4}>
              {t(`function.ocr.content.ITEMS1.CONTENT3`)}
            </MKTypography>
            <MKTypography variant="h5" color="text" mb={2}>
              {t(`function.ocr.content.ITEMS1.SUBTITLE4`)}
            </MKTypography>
            <MKTypography variant="body2" color="text" mb={10}>
              {t(`function.ocr.content.ITEMS1.CONTENT4`)}
            </MKTypography>
            <MKBox display="flex" alignItems="center">
              <MKBox
                width="3rem"
                height="3rem"
                variant="gradient"
                bgColor="info"
                color="white"
                coloredShadow="info"
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderRadius="xl"
              >
                <Icon fontSize="small">wb_incandescent</Icon>
              </MKBox>
              <MKTypography variant="h3" pl={2} my={1}>
                {t(`function.ocr.content.ITEMS2.TITLE`)}
              </MKTypography>
            </MKBox>
            {HorizontalLine}
            <MKTypography variant="h5" color="text" mb={2}>
              {t(`function.ocr.content.ITEMS2.SUBTITLE1`)}
            </MKTypography>
            <MKTypography variant="body2" color="text" mb={10}>
              {t(`function.ocr.content.ITEMS2.CONTENT1`)}
            </MKTypography>
            <MKBox mb={6} style={{ width: "100%" }}>
              <img
                src={OCRImg3}
                alt="OCR3"
                style={{ width: "100%", height: "auto" }}
              />
            </MKBox>
            <MKBox display="flex" alignItems="center">
              <MKBox
                width="3rem"
                height="3rem"
                variant="gradient"
                bgColor="info"
                color="white"
                coloredShadow="info"
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderRadius="xl"
              >
                <Icon fontSize="small">manage_accounts</Icon>
              </MKBox>
              <MKTypography variant="h3" pl={2} my={1}>
                {t(`function.ocr.content.ITEMS3.TITLE`)}
              </MKTypography>
            </MKBox>
            {HorizontalLine}
            <MKTypography variant="h5" color="text" mb={2}>
              {t(`function.ocr.content.ITEMS3.SUBTITLE1`)}
            </MKTypography>
            <MKTypography variant="body2" color="text" mb={4}>
              {t(`function.ocr.content.ITEMS3.CONTENT1`)}
            </MKTypography>
            <OCRTable />
            <MKTypography variant="h5" color="text" mt={6} mb={2}>
              {t(`function.ocr.content.ITEMS3.SUBTITLE2`)}
            </MKTypography>
            <MKTypography variant="body2" color="text" mb={10}>
              {t(`function.ocr.content.ITEMS3.CONTENT2`)}
            </MKTypography>
          </Grid>
        </Grid>
      </Container>
      {HorizontalLine}
      <Container>
        <Grid container>
          <Grid item xs={12} lg={5}>
            <FilledInfoCard
              color="info"
              icon="article"
              title={t(`function.ocr.content.ITEMS4.TITLE1`)}
              description={t(`function.ocr.content.ITEMS4.CONTENT1`)}
              action={{
                type: "external",
                route: "https://docs.coursemos.co.kr/docs/ocr",
                label: "Read docs",
              }}
            />
          </Grid>
          <Grid item xs={12} lg={5}>
            <FilledInfoCard
              color="info"
              icon="help_outline"
              title={t(`function.ocr.content.ITEMS4.TITLE2`)}
              description={t(`function.ocr.content.ITEMS4.CONTENT2`)}
              action={{
                type: "external",
                route: "/support",
                label: "Let's go",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default FeaturesOne;
