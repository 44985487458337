import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Slide from "@mui/material/Slide";
import CircularProgress from "@mui/material/CircularProgress";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import MKBox from "components/MKBox";
import MKAlert from "components/MKAlert";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import MKTextField from "components/MKTextField";
import { useTranslation } from "react-i18next";

function View({ children, title, height, ...rest }) {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("SMS");
  const [contentType, setContentType] = useState("COMM");
  const [countryCode, setCountryCode] = useState("82");
  const [from, setFrom] = useState("16000052");
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");
  const [to, setTo] = useState("");
  const labelWidth = "120px";

  const handleTabType = (event, newValue) => setActiveTab(newValue);

  const handleReset = () => {
    setContent("");
    setSubject("");
    setTo("");
  };

  const handleExecute = async () => {
    if (content.trim() !== "") {
      setLoading(true);
      try {
        const requestData = {
          action: "sms",
          type: type,
          contentType: contentType,
          countryCode: countryCode,
          from: from,
          subject: subject,
          content: content,
          messages: [
            {
              to: to,
            },
          ],
          // files: [
          //   {
          //     fileId: fileId,
          //   },
          // ],
          // reserveTime: reserveTime,
          // reserveTimeZone: reserveTimeZone,
        };
        const response = await axios.post(
          process.env.REACT_APP_API,
          requestData,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          },
        );
        const responseData = JSON.parse(response.data.response);
        if (
          responseData.statusCode === "202" &&
          responseData.statusName === "success"
        ) {
          setSuccess(true);
        } else {
          const errorCode = responseData.errors;
          setErrorMessage(errorCode);
          setError(true);
        }
      } catch (error) {
        setErrorMessage("전송에 실패하였습니다.");
        setError(true);
      } finally {
        setLoading(false);
      }
    } else {
      setErrorMessage("내용을 입력하세요.");
      setError(true);
    }
  };
  useEffect(() => {
    if (success) {
      setTimeout(() => setSuccess(false), 3000);
    }
    if (error) {
      setTimeout(() => setError(false), 3000);
    }
  }, [success, error]);

  return (
    <MKBox
      width="100%"
      position="relative"
      borderRadius="xl"
      shadow="lg"
      mb={12}
      sx={{ overflow: "hidden" }}
      {...rest}
    >
      <MKBox
        px={3}
        sx={{
          borderBottom: ({ borders: { borderWidth, borderColor } }) =>
            `${borderWidth[1]} solid ${borderColor}`,
        }}
      >
        <Grid container spacing={2} justifyContent="space-between" py={1}>
          <Grid item xs={12} lg={3}>
            <MKTypography variant="body1" pt={0.5}>
              {title}
            </MKTypography>
          </Grid>
          <Grid item xs={12} lg={3}>
            <AppBar position="static">
              <Tabs value={activeTab} onChange={handleTabType}>
                <Tab
                  icon={
                    <MKBox
                      component="i"
                      color="dark"
                      mr={1.25}
                      sx={{ fontSize: ({ typography: { size } }) => size.sm }}
                      className="fas fa-desktop"
                    />
                  }
                  label={t("tabs.DETAILS")}
                />
                <Tab
                  icon={
                    <MKBox
                      component="i"
                      color="dark"
                      mr={1.25}
                      sx={{ fontSize: ({ typography: { size } }) => size.sm }}
                      className="fas fa-user-astronaut"
                    />
                  }
                  label={t("tabs.EXPERIENCE")}
                />
              </Tabs>
            </AppBar>
          </Grid>
        </Grid>
      </MKBox>
      <MKBox display={activeTab === 0 ? "block" : "none"}>
        <MKBox width="100%" p={3}>
          <MKBox
            bgColor="grey-100"
            width="100%"
            height={height}
            maxHeight="40rem"
            borderRadius="xl"
            sx={{ overflowX: "hidden", overflowY: "scroll" }}
          >
            {children}
          </MKBox>
        </MKBox>
      </MKBox>
      <MKBox display={activeTab === 1 ? "block" : "none"} p={3}>
        <MKBox
          bgColor="grey-100"
          position="relative"
          width="100%"
          borderRadius="xl"
          sx={{ overflow: "hidden" }}
        >
          <MKBox pt={2} pr={2} pl={2} display="flex" alignItems="center">
            <MKTypography variant="button" style={{ marginRight: "10px" }}>
              메시지 종류:
            </MKTypography>
            <FormGroup
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginLeft: "25px",
              }}
            >
              <div style={{ width: labelWidth }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={type === "SMS"}
                      onChange={() => setType("SMS")}
                    />
                  }
                  label="SMS"
                />
              </div>
              <div style={{ width: labelWidth }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={type === "LMS"}
                      onChange={() => setType("LMS")}
                    />
                  }
                  label="LMS"
                />
              </div>
              <div style={{ width: labelWidth }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={type === "MMS"}
                      onChange={() => setType("MMS")}
                    />
                  }
                  label="MMS"
                />
              </div>
            </FormGroup>
          </MKBox>
          <MKBox pr={2} pl={2} display="flex" alignItems="center">
            <MKTypography variant="button" style={{ marginRight: "10px" }}>
              메시지 타입:
            </MKTypography>
            <FormGroup
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginLeft: "25px",
              }}
            >
              <div style={{ width: labelWidth }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={contentType === "COMM"}
                      onChange={() => setContentType("COMM")}
                    />
                  }
                  label="일반메시지"
                />
              </div>
              <div style={{ width: labelWidth }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={contentType === "AD"}
                      onChange={() => setContentType("AD")}
                    />
                  }
                  label="광고메시지"
                />
              </div>
            </FormGroup>
          </MKBox>
          <MKBox pr={2} pl={2} display="flex" alignItems="center">
            <MKTextField
              label="국가 코드"
              value={countryCode}
              onChange={(e) => setCountryCode(e.target.value)}
              fullWidth
              margin="normal"
            />
          </MKBox>
          <MKBox pr={2} pl={2} display="flex" alignItems="center">
            <MKTextField
              label="발신번호"
              value={from}
              onChange={(e) => setFrom(e.target.value)}
              fullWidth
              margin="normal"
            />
          </MKBox>
          {type === "LMS" || type === "MMS" ? (
            <MKBox pr={2} pl={2} display="flex" alignItems="center">
              <MKTextField
                label="메시지 제목 (LMS, MMS 전용)"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                fullWidth
                margin="normal"
              />
            </MKBox>
          ) : null}
          <MKBox pr={2} pl={2} display="flex" alignItems="center">
            <MKTextField
              label="메시지 내용"
              value={content}
              onChange={(e) => setContent(e.target.value)}
              fullWidth
              margin="normal"
              multiline
              rows={4}
              style={{ minHeight: "70px" }}
            />
          </MKBox>
          <MKBox pr={2} pl={2} display="flex" alignItems="center">
            <MKTextField
              label="수신번호"
              value={to}
              onChange={(e) => setTo(e.target.value)}
              fullWidth
              margin="normal"
            />
          </MKBox>
          <MKBox pr={2} pl={2} display="flex" justifyContent="flex-end">
            <MKButton
              variant="contained"
              color="primary"
              onClick={handleExecute}
            >
              {loading ? <CircularProgress size={24} /> : "전송"}
            </MKButton>
            <MKButton
              variant="outlined"
              color="secondary"
              onClick={handleReset}
              style={{ marginLeft: 8 }}
            >
              리셋
            </MKButton>
          </MKBox>
          {loading && (
            <MKBox textAlign="center" mt={2}>
              <CircularProgress />
            </MKBox>
          )}
          <Slide direction="down" in={error} unmountOnExit>
            <MKBox position="absolute" top="0.5rem" left={0} width="100%">
              <MKAlert
                width="100%"
                mx="auto"
                color="error"
                sx={{
                  minHeight: "3.5rem !important",
                  py: 1,
                  justifyContent: "center",
                }}
              >
                <MKTypography
                  variant="body2"
                  color="white"
                  fontWeight="regular"
                >
                  {errorMessage}
                </MKTypography>
              </MKAlert>
            </MKBox>
          </Slide>
          <Slide direction="down" in={success} unmountOnExit>
            <MKBox position="absolute" top="0.5rem" left={0} width="100%">
              <MKAlert
                width="100%"
                mx="auto"
                color="success"
                sx={{
                  minHeight: "3.5rem !important",
                  py: 1,
                  justifyContent: "center",
                }}
              >
                <MKTypography
                  variant="body2"
                  color="white"
                  fontWeight="regular"
                >
                  메세지가 성공적으로 전송되었습니다.
                </MKTypography>
              </MKAlert>
            </MKBox>
          </Slide>
        </MKBox>
      </MKBox>
    </MKBox>
  );
}

View.defaultProps = {
  height: "auto",
};

View.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  height: PropTypes.string,
};

export default View;
